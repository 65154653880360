import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { ImpressScreen } from "../../../../app/screens/Impress.screen";
import { DataPrivacyScreen } from "../../../../app/screens/DataPrivacy.screen";

export const IMPRESS_SCREEN_PATH = "/impressum";
export const DATA_PRIVACY_SCREEN_PATH = "/datenschutz";
export const LegalRoutes: PrivateRouteDefinition[] = [
	{
		path: IMPRESS_SCREEN_PATH,
		component: <ImpressScreen />,
	},
	{
		path: DATA_PRIVACY_SCREEN_PATH,
		component: <DataPrivacyScreen />,
	},
];

import React from "react";
import { Navigate, useMatch } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { ContentSubmissionScreen_ContentSubmissionFragment$key } from "@generated/ContentSubmissionScreen_ContentSubmissionFragment.graphql";
import { ContentSubmissionScreen_Query } from "@generated/ContentSubmissionScreen_Query.graphql";
import { ContentElement } from "../../infecto-lms-webapp/components/content-element/ContentElement.component";
import { NodeContainer } from "../../infecto-lms-webapp-impl/containers/Node.container";
import { ContentSubmissionEnd } from "../components/ContentSubmissionEnd.component";
import {
	CONTENT_SUBMISSION_ID_URL_PARAM,
	getContentSubmissionPath,
} from "../../infecto-lms-webapp-impl/router/routes/auth/learn.routes";

const QUERY = graphql`
	query ContentSubmissionScreen_Query($submissionId: ID!) {
		node(id: $submissionId) {
			... on ContentSubmission {
				id
				...ContentSubmissionScreen_ContentSubmissionFragment
			}
		}
	}
`;

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionScreen_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			...NodeContainer_LearnOpportunityV2Fragment
		}
		definition {
			status
		}
		...ContentSubmissionEnd_ContentSubmissionFragment
		...ContentElement_ContentSubmissionFragment
	}
`;

export function ContentSubmissionScreen() {
	const {
		params: { contentSubmissionId },
	} = useMatch(getContentSubmissionPath(CONTENT_SUBMISSION_ID_URL_PARAM))!;

	const data = useLazyLoadQuery<ContentSubmissionScreen_Query>(QUERY, {
		submissionId: contentSubmissionId!,
	});

	const contentSubmission = useFragment<ContentSubmissionScreen_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		data.node,
	);

	return contentSubmission && contentSubmission.learnOpportunity ? (
		<NodeContainer learnOpportunityFragmentRef={contentSubmission.learnOpportunity}>
			<>
				{contentSubmission.definition.status !== "active" && (
					<ContentSubmissionEnd contentSubmissionFragmentRef={contentSubmission} />
				)}
				{contentSubmission.definition.status === "active" && (
					<ContentElement contentSubmissionFragmentRef={contentSubmission} />
				)}
			</>
		</NodeContainer>
	) : (
		<Navigate to={"/"} />
	);
}

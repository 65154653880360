/**
 * @generated SignedSource<<bcc872aa95abed3c05c1fa89f405d48e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Nodes_NodeFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCard_NodeFragment">;
  readonly " $fragmentType": "Nodes_NodeFragment";
};
export type Nodes_NodeFragment$key = {
  readonly " $data"?: Nodes_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nodes_NodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "Nodes_NodeFragment"
};

(node as any).hash = "53fd9c8116287e09c222d67dd10e0539";

export default node;

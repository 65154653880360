/**
 * @generated SignedSource<<42064713198c09de7c750f174c6b815a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type SupplyEfnNumberInput = {
  clientMutationId?: string | null;
  efnNumber: string;
};
export type CommunicationSettingsScreen_SupplyEfnNumberMutation$variables = {
  input: SupplyEfnNumberInput;
};
export type CommunicationSettingsScreen_SupplyEfnNumberMutation$data = {
  readonly InfectopharmAuth: {
    readonly supplyEfnNumber: {
      readonly clientMutationId: string | null;
      readonly user: {
        readonly extension: {
          readonly efnNumber?: string | null;
          readonly lastName?: string | null;
          readonly salutation?: Salutation | null;
          readonly title?: string | null;
        };
      } | null;
    } | null;
  };
};
export type CommunicationSettingsScreen_SupplyEfnNumberMutation = {
  response: CommunicationSettingsScreen_SupplyEfnNumberMutation$data;
  variables: CommunicationSettingsScreen_SupplyEfnNumberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "efnNumber",
      "storageKey": null
    }
  ],
  "type": "InfectopharmUserExtensionImpl",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationSettingsScreen_SupplyEfnNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InfectopharmAuthMutations",
        "kind": "LinkedField",
        "name": "InfectopharmAuth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SupplyEfnNumberPayload",
            "kind": "LinkedField",
            "name": "supplyEfnNumber",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunicationSettingsScreen_SupplyEfnNumberMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InfectopharmAuthMutations",
        "kind": "LinkedField",
        "name": "InfectopharmAuth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SupplyEfnNumberPayload",
            "kind": "LinkedField",
            "name": "supplyEfnNumber",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b40ef9483524deccd342466ed955b3ec",
    "id": null,
    "metadata": {},
    "name": "CommunicationSettingsScreen_SupplyEfnNumberMutation",
    "operationKind": "mutation",
    "text": "mutation CommunicationSettingsScreen_SupplyEfnNumberMutation(\n  $input: SupplyEfnNumberInput!\n) {\n  InfectopharmAuth {\n    supplyEfnNumber(input: $input) {\n      clientMutationId\n      user {\n        extension {\n          __typename\n          ... on InfectopharmUserExtensionImpl {\n            salutation\n            title\n            lastName\n            efnNumber\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08853d34f65378b2aecec0477dbdbd68";

export default node;

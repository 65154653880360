import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Knob } from "primereact/knob";
import styled from "styled-components";
import { Message } from "primereact/message";
import { ContentSubmissionFinishedMessageCard } from "./ContentSubmissionFinishedMessageCard.component";
import {
	ContentSubmissionEnd_ContentSubmissionFragment$key,
	RewardKind,
	RewardResultKind,
} from "@generated/ContentSubmissionEnd_ContentSubmissionFragment.graphql";
import { FailedContentButton } from "./FailedContentButton";
import { NavigateDirectToQuestionsAdvanceTreeButton } from "./NavigateDirectToQuestionsAdvanceTreeButton.component";
import { ProjectContentElementContainer } from "../../infecto-lms-webapp-impl/containers/ProjectContentElement.container";
import { formatDateTime } from "src/infecto-lms-webapp/components/DateTimeDisplay.component";
import { isTemporarilyBlockedUntilUtil } from "../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";
import { isPermanentlyBlockedUtil } from "../utils/advancement-result/is-permanently-blocked";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionEnd_ContentSubmissionFragment on ContentSubmission {
		learnOpportunity {
			id
			nextContentNode {
				structureDefinition {
					title
				}
				typeDefinition {
					... on LearnOpportunityELearningContentTypeDefinition {
						containedElementTypes
					}
				}
			}
			... on LearnOpportunityV2 {
				nextContentNode {
					structureDefinition {
						title
					}
				}
				root {
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							extension {
								... on LearnOpportunityRootExtensionImpl {
									configConsequences {
										rewardExpired
										showAnswerExpired
									}
								}
							}
						}
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
					contentNodeAdvancementResult {
						status
						... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
							configResults {
								configType

								... on RestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockPermanentlyWhenRewardedRestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult {
									blockedUntil
								}
							}
						}
					}
				}
			}
			...NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment
			...NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment
		}
		definition {
			status

			... on FailedELearningContentSubmissionDefinition {
				relativeProgress {
					numCorrect
					numElements
					percentageCorrect
				}
			}

			... on PassedELearningContentSubmissionDefinition {
				relativeProgress {
					numCorrect
					numElements
					percentageCorrect
				}
				rewardResults {
					kind
					rewardKind
					... on PointsRewardWasPossibleResult {
						amount
						pointsPoolName
					}
					... on CertificateRewardWasPossibleResult {
						certificateName
						issuedCertificate {
							fileRef {
								url
							}
						}
					}
				}
			}
		}
		...ContentElementContainer_ContentSubmissionFragment
		...FailedContentButton_ContentSubmissionFragment
		...ProjectContentElementContainer_ContentSubmissionFragment
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: ContentSubmissionEnd_ContentSubmissionFragment$key;
}

const RewardsContainer = styled.div`
	max-width: 1024px;
`;

export function ContentSubmissionEnd({ contentSubmissionFragmentRef }: OwnProps) {
	const contentSubmission = useFragment<ContentSubmissionEnd_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);
	const configConsequences =
		contentSubmission.learnOpportunity?.root?.structureDefinition.extension?.configConsequences;
	const nextContentNodeContainedElementsType =
		contentSubmission.learnOpportunity?.nextContentNode?.typeDefinition.containedElementTypes;
	const containedElementTypes =
		contentSubmission.learnOpportunity?.typeDefinition.containedElementTypes;
	const showAnswerNextNodeIsMultiplechoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird. \n Wenn Sie möchten, können Sie nun die CME-Fragen zu diesem Modul beantworten. Da die Zertifizierung bereits abgelaufen ist, können Sie sich - fall gewünscht - die richtigen Antworten anzeigen lassen. Klicken Sie dazu auf den entsprechenden Button`;
	const nodeIsNotMultipleChoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird.`;
	const nextNodeIsMultipleChoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird.\n
Sie können nun CME-Punkte erwerben, indem Sie die folgende Lernerfolgskontrolle erfolgreich absolvieren. Sie kommen zu den Multiple-Choice-Fragen, wenn Sie unten auf "Zu den Fragen“ klicken - es sei denn, Sie haben das Modul bereits bestanden oder die Fragen sind nach einem Fehlversuch gesperrt.
`;

	const isTemporarilyBlockedUntil = isTemporarilyBlockedUntilUtil(
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const isPermanentlyBlocked = isPermanentlyBlockedUtil(
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);
	const rewardingWasPossible = (
		rewardResults: ReadonlyArray<{
			readonly amount?: number;
			readonly certificateName?: string;
			readonly issuedCertificate?: {
				readonly fileRef: {
					readonly url: string | null;
				} | null;
			} | null;
			readonly kind: RewardResultKind;
			readonly pointsPoolName?: string;
			readonly rewardKind: RewardKind;
		}>,
	): boolean => {
		return (
			rewardResults.filter(
				(rewardResult) =>
					rewardResult.kind == "PointsRewardWasPossibleResult" ||
					rewardResult.kind == "CertificateRewardWasPossibleResult" ||
					rewardResult.kind == "EmailRewardWasPossibleResult",
			).length > 0
		);
	};

	const backgroundRewardDefiner = (reward: any) => {
		if (reward.kind === "CertificateRewardWasPossibleResult") {
			return "bg-primary";
		} else return "surface-200";
	};

	return (
		<ProjectContentElementContainer
			hideBack
			contentSubmissionFragmentRef={contentSubmission}
			nextButton={
				contentSubmission.learnOpportunity && (
					<div>
						<NavigateDirectToQuestionsAdvanceTreeButton
							learnOpportunityFragmentRef={contentSubmission.learnOpportunity}
							showFinished={true}
						/>
						<FailedContentButton contentSubmissionRef={contentSubmission} />
					</div>
				)
			}
		>
			<div className="flex flex-column justify-content-center align-items-center flex-grow-1 p-5 pt-8">
				{contentSubmission.definition.relativeProgress && (
					<div className="relative flex justify-content-center align-items-center mb-3">
						<Knob
							min={0}
							max={100}
							strokeWidth={10}
							size={200}
							value={contentSubmission.definition.relativeProgress.percentageCorrect}
							showValue={false}
						/>
						<div className="absolute flex justify-content-center flex-column">
							<div className="text-lg font-bold text-center">
								{contentSubmission.definition.relativeProgress.numCorrect} /{" "}
								{contentSubmission.definition.relativeProgress.numElements}
							</div>
							{containedElementTypes?.includes("multipleChoice") && (
								<div className="text-center">korrekt</div>
							)}
						</div>
					</div>
				)}

				{containedElementTypes?.includes("multipleChoice") &&
					(contentSubmission.definition.status === "passed" ? (
						<div className="mb-5">
							<h1 className="text-center">Herzlichen Glückwunsch</h1>
							<p className="text-lg text-center">
								Sie haben die Lernerfolgskontrolle „
								{contentSubmission.learnOpportunity?.root?.structureDefinition.title}” erfolgreich
								bestanden.
							</p>
						</div>
					) : (
						<div>
							<h1 className="text-center">Schade.</h1>
							<p className="text-lg text-center">Leider nicht bestanden.</p>
						</div>
					))}

				{contentSubmission.definition.status === "passed" &&
					!rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) && (
						<Message
							className="mb-5"
							severity="warn"
							content="Für dieses Modul wurden keine Punkte / Zertifikate vergeben."
						/>
					)}

				{isTemporarilyBlockedUntil && (
					<Message
						className="mb-5"
						severity="error"
						content={`Durch die Falschbeantwortung wird dieses Modul bis ${formatDateTime(
							isTemporarilyBlockedUntil.blockedUntil as string,
						)} Uhr gesperrt.`}
					/>
				)}

				{isPermanentlyBlocked && (
					<Message
						className="mb-5"
						severity="error"
						content="Sie haben keinen weiteren Versuch mehr."
					/>
				)}

				{rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) && (
					<RewardsContainer className="w-12">
						{contentSubmission.definition.rewardResults
							?.filter((r) => ["Points", "Certificate"].includes(r.rewardKind))
							.map((reward, index) => (
								<div
									key={"reward-" + index}
									className={`border-round border-400 border-1 padding-3 flex mb-3 p-4 align-items-center justify-content-center ${backgroundRewardDefiner(
										reward,
									)}`}
								>
									{reward.rewardKind === "Points" && (
										<div className="text-xl font-bold">
											Sie haben {reward.amount} {reward.pointsPoolName} erhalten!
										</div>
									)}

									{reward.rewardKind === "Certificate" && (
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={reward.issuedCertificate?.fileRef?.url || ""}
										>
											<div className="text-xl text-white font-bold">
												Sie haben ein {reward.certificateName} erhalten! Hier klicken zum
												Herunterladen.
											</div>
										</a>
									)}
								</div>
							))}
					</RewardsContainer>
				)}

				{configConsequences?.rewardExpired &&
					!configConsequences.showAnswerExpired &&
					!containedElementTypes?.includes("multipleChoice") && (
						<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
					)}
				{configConsequences?.rewardExpired &&
					configConsequences.showAnswerExpired &&
					(nextContentNodeContainedElementsType?.includes("multipleChoice") ? (
						<ContentSubmissionFinishedMessageCard
							message={showAnswerNextNodeIsMultiplechoiceMessage}
						/>
					) : !containedElementTypes?.includes("multipleChoice") ? (
						<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
					) : null)}
				{contentSubmission.definition.status === "passed" &&
					!rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) &&
					!configConsequences?.rewardExpired && (
						<>
							{" "}
							{nextContentNodeContainedElementsType?.includes("multipleChoice") ? (
								<ContentSubmissionFinishedMessageCard message={nextNodeIsMultipleChoiceMessage} />
							) : (
								!containedElementTypes?.includes("multipleChoice") && (
									<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
								)
							)}
						</>
					)}
				{contentSubmission.definition.status === "passed" &&
					rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) &&
					!configConsequences?.rewardExpired && (
						<>
							{" "}
							{nextContentNodeContainedElementsType?.includes("multipleChoice") ? (
								<ContentSubmissionFinishedMessageCard message={nextNodeIsMultipleChoiceMessage} />
							) : (
								!containedElementTypes?.includes("multipleChoice") && (
									<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
								)
							)}
						</>
					)}
			</div>
		</ProjectContentElementContainer>
	);
}

import React from "react";
import { CSSProperties } from "react";
import Logo from "../assets/logo.png";

interface OwnProps {
	width?: number;
	className?: string;
	styles?: CSSProperties;
}
export const ProjectLogoComponent = ({ width, styles, className }: OwnProps) => {
	return <img alt="WissenWirkt" src={Logo} width={width} style={styles} className={className} />;
};

import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { UserTreesScreen } from "../../../../app/screens/UserTrees.screen";
import { ROLE_ADMIN, ROLE_USER } from "../../../../infecto-lms-webapp/router/UserRoles";

export const TREE_STATES_PATH = "/meine-lernerfahrungen";

export const LearnStateRoutes: PrivateRouteDefinition[] = [
	{
		path: TREE_STATES_PATH,
		component: <UserTreesScreen />,
		requiredRoles: [ROLE_ADMIN, ROLE_USER],
	},
];

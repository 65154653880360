import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import { useFragment } from "react-relay";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import { FailedContentButton_ContentSubmissionFragment$key } from "@generated/FailedContentButton_ContentSubmissionFragment.graphql";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment FailedContentButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			status
		}
	}
`;
interface OwnProps {
	contentSubmissionRef: FailedContentButton_ContentSubmissionFragment$key;
}
export const FailedContentButton = ({ contentSubmissionRef }: OwnProps) => {
	const navigate = useNavigate();
	const contentSubmission = useFragment<FailedContentButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionRef,
	);

	return contentSubmission.definition.status == "failed" ? (
		<PrimaryButton onClick={() => navigate(DASHBOARD_PATH)} label="Zurück zur Übersicht" />
	) : null;
};

import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { CommunicationSettingsScreen } from "../../../../app/screens/CommunicationSettings.screen";

export const COMMUNICATION_SETTINGS_SCREEN_PATH = "/einstellungen";
export const CommunicationSettingsRoutes: PrivateRouteDefinition[] = [
	{
		path: COMMUNICATION_SETTINGS_SCREEN_PATH,
		component: <CommunicationSettingsScreen />,
	},
];

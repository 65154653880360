import React from "react";
import styled from "styled-components";

interface OwnProps {
	message: string;
}
export const ContentSubmissionFinishedMessageCard = ({ message }: OwnProps) => {
	return (
		<MessageCardContainer>
			{message !== "" ? (
				<CardMessage>
					<h4>{message}</h4>
					<h5>Ihr Team von InfectoPharm</h5>
				</CardMessage>
			) : null}
		</MessageCardContainer>
	);
};

const MessageCardContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CardMessage = styled.div`
	width: 80%;
	white-space: pre-line;
`;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { Navigate, useMatch } from "react-router-dom";
import { NodeScreen_Query } from "@generated/NodeScreen_Query.graphql";
import { ShowAnswersButton } from "../../infecto-lms-webapp/components/content-element/ShowAnswersButton.component";
import { NodeDescription } from "../../infecto-lms-webapp/components/node/NodeDescription.component";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { NodeBreadcrumbs } from "../../infecto-lms-webapp/components/node/NodeBreadcrumbs.component";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { NodeConfigurationsDisplay } from "../../infecto-lms-webapp/components/node/NodeConfigurationsDisplay.component";
import { Message } from "primereact/message";
import {
	ElementTypeV2,
	NodeScreen_NodeFragment$key,
} from "@generated/NodeScreen_NodeFragment.graphql";
import {
	getLearnOpportunityPath,
	LEARN_OPPORTUNITY_ID_URL_PARAM,
} from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { NodeLeftFrame } from "../components/NodeLeftFrame.component";
import { NodeInstructors } from "../../infecto-lms-webapp/components/node/NodeInstructors.component";
import { TreeAdvancementMessage } from "../../infecto-lms-webapp/components/node/TreeAdvancementMessage.component";
import { MainContainerWithTopAndBottomBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopAndBottomBar.container";
import { DateTimeDisplay } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { WrapperContainerWithScrollbar } from "../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";
import {
	footerPixelsLeftFrame,
	footerPixelsMainContainer,
	footerPixelsRightFrame,
} from "../../infecto-lms-webapp/hooks/scrollbarComponentHelpers";

const QUERY = graphql`
	query NodeScreen_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NodeScreen_NodeFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeScreen_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							dateTimeOnlyAdminsVisibility
							reducedDataInTree {
								elementTypes
							}
							configConsequences {
								rewardExpired
								showAnswerExpired
							}
						}
					}
					icon {
						url
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
		}
		shortDescription
		description
		instructors {
			superId
		}
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
					...ShowAnswersButton_LearnOpportunityFragment
				}
			}
			definitionType
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
		...NodeBreadcrumbs_NodeFragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeContentElementsList_NodeFragment
		...AttachmentDisplay_LearnOpportunityV2Fragment
		...NodeDescription_NodeFragment
		...ShowAnswersButton_LearnOpportunityFragment
		...TreeAdvancementMessage_NodeFragment
	}
`;

export function NodeScreen() {
	const {
		params: { learnOpportunityId },
	} = useMatch(getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM))!;

	const query = useLazyLoadQuery<NodeScreen_Query>(
		QUERY,
		{
			learnOpportunityId: learnOpportunityId!,
		},
		{ fetchPolicy: "network-only" },
	);

	const learnOpportunity = useFragment<NodeScreen_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		query.node,
	)!;

	if (!learnOpportunity) {
		return <Navigate to="*" />;
	}

	usePageTitle(learnOpportunity.structureDefinition.title);

	const onlyAdminVisibilityDateTime =
		learnOpportunity?.root?.structureDefinition?.extension?.dateTimeOnlyAdminsVisibility;

	const hasMultipleChoice =
		learnOpportunity.typeDefinition.children?.filter(
			(child: {
				readonly id: string;
				readonly typeDefinition: {
					readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
				};
			}) => child.typeDefinition.containedElementTypes?.includes("multipleChoice"),
		).length || 0 > 0;

	const isModuleExpired =
		learnOpportunity.root?.structureDefinition.extension?.configConsequences?.showAnswerExpired;
	return (
		<DefaultContainer
			collapsed
			screenName="learn-opportunity"
			containerId={learnOpportunity.id}
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopAndBottomBar
				footerPixels={footerPixelsMainContainer(isModuleExpired || false)}
				className="w-12 flex-grow-1 grid grid-nogutter"
			>
				<NodeLeftFrame
					footerPixels={footerPixelsLeftFrame(isModuleExpired || false)}
					rootId={learnOpportunity?.root?.id!}
				/>

				<DescriptionAndAttachmentsSideBar
					footerPixels={footerPixelsRightFrame(isModuleExpired || false)}
					className="col-12 xl:col-9 p-0 bg-white border-left-1 border-300 flex flex-column flex-grow-1"
				>
					<WrapperContainerWithScrollbar
						width={98}
						marginBottom={100}
						footerPixels={footerPixelsRightFrame(isModuleExpired || false)}
					>
						<DescriptionContainer className="flex pl-5 pt-2 pr-5 flex-grow-1 relative md:justify-content-between">
							<div className="flex-grow-1 md:relative md:pr-3" style={{ maxWidth: 1024 }}>
								<div className="hidden md:block">
									<NodeBreadcrumbs learnOpportunityFragmentRef={learnOpportunity} />
								</div>

								<div className="flex lg:align-items-center flex-column lg:flex-row">
									<h1 className="mb-4">{learnOpportunity.structureDefinition.title}</h1>
									{onlyAdminVisibilityDateTime !== null &&
										learnOpportunity?.root?.id === learnOpportunityId && (
											<Message
												className="lg:mt-4 mb-4 w-12 md:w-12 lg:w-4 p-3 lg:ml-4"
												severity="warn"
												content={
													<div>
														Sichtbar ab:{" "}
														<DateTimeDisplay
															hideTimezone
															value={onlyAdminVisibilityDateTime as string}
														/>
													</div>
												}
											/>
										)}
								</div>

								<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />
								<TreeAdvancementMessage nodeFragment={learnOpportunity} />

								{learnOpportunity.image?.url && (
									<div className="mb-4">
										<img
											style={{ maxWidth: "100%" }}
											alt={learnOpportunity.structureDefinition.title}
											src={learnOpportunity.image.url}
										/>
									</div>
								)}

								{(learnOpportunity.root?.structureDefinition.extension?.configConsequences
									?.rewardExpired ||
									false) && (
									<div className="mb-4">
										<Message
											severity="warn"
											content="ACHTUNG: Dieses Modul ist abgelaufen. Es werden keine Punkte / Zertifikate für das Bestehen vergeben."
										/>
									</div>
								)}

								{(learnOpportunity.root?.structureDefinition.extension?.configConsequences
									?.showAnswerExpired ||
									false) && (
									<div className="mb-4">
										<Message
											severity="warn"
											content="Sie können sich nun die Antworten anzeigen lassen."
										/>
									</div>
								)}

								<NodeDescription learnOpportunityFragmentRef={learnOpportunity} />
								{learnOpportunity?.instructors.length > 0 && (
									<NodeInstructors ids={learnOpportunity.instructors.map((i) => i.superId)} />
								)}
								<NodeConfigurationsDisplay learnOpportunityFragmentRef={learnOpportunity} />
							</div>
							{/*		<AttachmentDisplay
							openInitially={true}
							learnOpportunityFragmentRef={learnOpportunity}
						/>*/}
						</DescriptionContainer>
					</WrapperContainerWithScrollbar>
					{isModuleExpired && (
						<Footer className="border-top-1 border-300 flex p-5 z-2 bg-white absolute bottom-0 right-0 w-12">
							<div className="ml-auto flex flex-column md:flex-row">
								{learnOpportunity.typeDefinition.children
									? learnOpportunity.typeDefinition.children
											?.filter(
												(c) =>
													c.typeDefinition.containedElementTypes?.filter((e) =>
														e.includes("multipleChoice"),
													).length,
											)
											.map((ch) => (
												<div key={ch.id}>
													<ShowAnswersButton learnOpportunityFragmentRef={ch} />
													{/*		<AdvanceContentNodeButton learnOpportunityFragmentRef={learnOpportunity} />*/}
												</div>
											))
									: hasMultipleChoice && (
											<ShowAnswersButton learnOpportunityFragmentRef={learnOpportunity} />
									  )}
								{/*	<AdvanceTreeButton
								learnOpportunityFragmentRef={learnOpportunity}
								showFinished={false}
							/>*/}
							</div>
						</Footer>
					)}
				</DescriptionAndAttachmentsSideBar>
			</MainContainerWithTopAndBottomBar>
		</DefaultContainer>
	);
}

const DescriptionContainer = styled.div`
	@media (max-width: 768px) {
		padding-bottom: 200px;
	}
	height: 100%;
`;

interface DescriptionProps {
	footerPixels: number;
}
const DescriptionAndAttachmentsSideBar = styled.div<DescriptionProps>`
	max-height: calc(100vh - ${(props) => props.footerPixels}px);
	padding-bottom: 200px;
	overflow: auto;
	@media only screen and (min-width: 1020px) {
		overflow: hidden;
	}
`;

const Footer = styled.div`
	min-height: 102px;
`;

import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import React, { ReactNode } from "react";
import { NodeContainer_LearnOpportunityV2Fragment$key } from "@generated/NodeContainer_LearnOpportunityV2Fragment.graphql";
import { NodeTagList } from "../../infecto-lms-webapp/components/node/NodeTagList.component";
import { DurationDisplay } from "../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { MainContainerWithTopAndBottomBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopAndBottomBar.container";
import { NodeConfigurationsDisplay } from "../../infecto-lms-webapp/components/node/NodeConfigurationsDisplay.component";
import { ProjectNodeTopBar } from "../components/relay/ProjectNodeTopBar.component";
import { NodeContentElementsList } from "../../infecto-lms-webapp/components/node/NodeContentElementsList.component";
import { AttachmentHeftDisplay } from "../../infecto-lms-webapp/components/attachment/AttachmentHeftDisplay.component";
import { WrapperContainerWithScrollbar } from "../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";
import {
	footerPixelsLeftFrame,
	footerPixelsRightFrame,
} from "../../infecto-lms-webapp/hooks/scrollbarComponentHelpers";
import { singularPluralPointsDisplay } from "../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeContainer_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		root {
			...NodeContentElementsList_NodeFragment
			id
			typeDefinition {
				definitionType
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
				}
				... on LearnOpportunityBranchTypeDefinition {
					children {
						...ProjectContentButtonCME_LearnOpportunityFragment
						id
						shortDescription
						structureDefinition {
							title
						}
						typeDefinition {
							definitionType
							... on LearnOpportunityELearningContentTypeDefinition {
								containedElementTypes
								contentNodeAdvancementResult {
									status
									... on CanNotBeStartedContentNodeAdvancementResult {
										reasonConfigTypes
									}
									... on ContinueContentNodeAdvancementResult {
										activeContentSubmissionId
									}
									... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
										configResults {
											configType

											... on RestartIfFailedContentConfigResult {
												canBeRestarted
											}

											... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
												canBeRestarted
											}

											... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
												blockedUntil
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		root {
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					icon {
						url
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								restartTriesCount
								isRewardedTo
								rewardExpired
								showAnswerExpired
							}
						}
					}
				}
			}
		}
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						lengthInSecondsOverride
						cmePoints
					}
				}
				containedElementTypes
			}
		}
		...ProjectNodeTopBar_LearnOpportunityV2Fragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeDescription_NodeFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeContainer_LearnOpportunityV2Fragment$key;
	sidebarSlot?: ReactNode;
	children: any;
}

const Icon = styled.img`
	max-width: 100px;
`;

const LeftFrame = styled.div`
	position: fixed;
	left: 0;
	height: 85%;
	width: 25%;
	@media screen and (max-width: 1240px) {
		display: none !important;
	}
`;

const ContentFrame = styled.div`
	margin-left: 25%;
	@media screen and (max-width: 1240px) {
		margin-left: 0;
	}
`;

export function NodeContainer({ learnOpportunityFragmentRef, sidebarSlot, children }: OwnProps) {
	const learnOpportunity = useFragment<NodeContainer_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const hasMultipleChoice =
		learnOpportunity.typeDefinition.containedElementTypes?.includes("multipleChoice") ||
		learnOpportunity.typeDefinition.containedElementTypes?.includes("randomMultipleChoice");

	const hasPoints = () => {
		return learnOpportunity.typeDefinition.extension?.cmePoints || -1 > 0;
	};

	const lengthInSeconds = learnOpportunity.typeDefinition.extension?.lengthInSecondsOverride;

	interface PointsProps {
		points: number | null | undefined;
		type: string;
	}

	const AvailablePoints = ({ points, type }: PointsProps) => {
		return (
			<div className="text-base" style={{ textAlign: "right" }}>
				{(points || -1) > 0 && (
					<div>
						{type}
						{singularPluralPointsDisplay(points!)}
						<span className="ml-2 text-base font-bold">{points}</span>
					</div>
				)}
			</div>
		);
	};
	return (
		<div className="flex flex-column flex-grow-1">
			<ProjectNodeTopBar learnOpportunityFragmentRef={learnOpportunity} className="surface-100" />
			<MainContainerWithTopAndBottomBar
				footerPixels={footerPixelsRightFrame(hasMultipleChoice || false)}
				className="flex-column  flex-nowrap lg:flex-wrap lg:flex-row grid flex-grow-1"
			>
				<LeftFrame className="col-12 xl:col-3 p-0 flex-column surface-100 pl-4 border-right-1 border-300 hidden md:flex">
					<WrapperContainerWithScrollbar
						width={100}
						marginBottom={0}
						footerPixels={footerPixelsLeftFrame(hasMultipleChoice || false)}
					>
						<div className="pr-4">
							<div className="flex pt-5">
								{learnOpportunity.root?.structureDefinition.icon?.url && (
									<Icon
										className="mr-4"
										alt={learnOpportunity.root.structureDefinition.title}
										src={learnOpportunity.root.structureDefinition.icon.url}
									/>
								)}

								{learnOpportunity.root?.structureDefinition && (
									<NodeTagList
										className="justify-content-end align-items-start flex-grow-1"
										learnOpportunityRootStructureDefinitionFragmentRef={
											learnOpportunity.root.structureDefinition
										}
									/>
								)}
							</div>

							<div className="flex align-items-center pt-4 pb-2">
								<table className="w-full">
									<tbody>
										<tr>
											<td>
												{" "}
												{lengthInSeconds && lengthInSeconds > 0 && (
													<div className="text-base">
														Dauer: <DurationDisplay duration={lengthInSeconds || 0} />
													</div>
												)}
											</td>
											{learnOpportunity.root?.structureDefinition.extension?.configConsequences
												?.rewardExpired || false ? (
												<td className="text-base" style={{ textAlign: "right" }}>
													Übungsmodul
												</td>
											) : (
												<td>
													{hasPoints() ? (
														<AvailablePoints
															type={"CME-"}
															points={learnOpportunity.typeDefinition.extension?.cmePoints}
														/>
													) : null}
												</td>
											)}
										</tr>
									</tbody>
								</table>
							</div>

							<hr className="w-12" />
							<h2 className="mt-0 mb-0 text-base">{learnOpportunity.structureDefinition.title}</h2>
							<hr className="w-12" />
							{/*<NodeDescription learnOpportunityFragmentRef={learnOpportunity} />*/}
							<NodeConfigurationsDisplay
								className={"text-base"}
								learnOpportunityFragmentRef={learnOpportunity}
							/>
							<NodeContentElementsList learnOpportunityFragmentRef={learnOpportunity.root!} />
							<AttachmentHeftDisplay
								learnOpportunityFragmentRef={learnOpportunity}
								marginTopValue={"auto"}
							/>
						</div>
					</WrapperContainerWithScrollbar>
					{sidebarSlot}
				</LeftFrame>
				<ContentFrame className="col-12 xl:col-9 p-0 flex flex-grow-1">{children}</ContentFrame>
			</MainContainerWithTopAndBottomBar>
		</div>
	);
}

/**
 * @generated SignedSource<<3d3600c482917fd142ea799191961d2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type UserTreesScreen_Query$variables = {};
export type UserTreesScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly efnNumber?: string | null;
            readonly lastName?: string | null;
            readonly salutation?: Salutation | null;
            readonly title?: string | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"DefaultContainer_UserDataFragment" | "PointsPoolDisplay_UserFragment" | "UserStatusMessage_UserFragment">;
        };
      } | null;
    };
  };
};
export type UserTreesScreen_Query = {
  response: UserTreesScreen_Query$data;
  variables: UserTreesScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salutation",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "efnNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserTreesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v0/*: any*/),
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "type": "InfectopharmUserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DefaultContainer_UserDataFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserStatusMessage_UserFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PointsPoolDisplay_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserTreesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v0/*: any*/),
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "globalUserStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "limitByDays",
                                    "value": 1825
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "pointPoolId",
                                    "value": "UG9pbnRQb29sOmNtZS1wb2ludHM="
                                  }
                                ],
                                "concreteType": "CountedPoints",
                                "kind": "LinkedField",
                                "name": "pointsInPoolV2",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "points",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pointPoolName",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "pointsInPoolV2(limitByDays:1825,pointPoolId:\"UG9pbnRQb29sOmNtZS1wb2ludHM=\")"
                              }
                            ],
                            "type": "InfectopharmUserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d22f8acabaa3218f07ebc8a742903dab",
    "id": null,
    "metadata": {},
    "name": "UserTreesScreen_Query",
    "operationKind": "query",
    "text": "query UserTreesScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extension {\n            __typename\n            ... on InfectopharmUserExtensionImpl {\n              salutation\n              title\n              lastName\n              efnNumber\n            }\n          }\n          ...DefaultContainer_UserDataFragment\n          ...UserStatusMessage_UserFragment\n          ...PointsPoolDisplay_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment PointsPoolDisplay_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      pointsInPoolV2(pointPoolId: \"UG9pbnRQb29sOmNtZS1wb2ludHM=\", limitByDays: 1825) {\n        points\n        pointPoolName\n      }\n    }\n  }\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n\nfragment UserStatusMessage_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      globalUserStatus\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b02729967df6b65dae2db7c2e33b60b7";

export default node;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { getLearnOpportunityPath } from "../../router/routes/auth/nodes.routes";
import { ProjectNodeTopBar_LearnOpportunityV2Fragment$key } from "@generated/ProjectNodeTopBar_LearnOpportunityV2Fragment.graphql";
import styled from "styled-components";
import { useMobileOrientation } from "react-device-detect";
import { SecondaryButton } from "../../../infecto-lms-webapp/components/buttons/SecondaryButton";
const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment ProjectNodeTopBar_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
			}
		}
		root {
			id
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: ProjectNodeTopBar_LearnOpportunityV2Fragment$key;
	className?: string;
}

const Header = styled.div`
	z-index: 1000;
`;

export function ProjectNodeTopBar({ learnOpportunityFragmentRef, className }: OwnProps) {
	const navigate = useNavigate();
	const learnOpportunity = useFragment<ProjectNodeTopBar_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const orientation = useMobileOrientation();
	return (
		<div>
			{(orientation.isPortrait || window.innerWidth > 1180) && (
				<Header className={`border-bottom-1 border-300 p-2 md:p-3 flex ${className || ""}`}>
					<div className="flex align-items-center">
						<SecondaryButton
							onClick={() => {
								learnOpportunity.root
									? navigate(getLearnOpportunityPath(learnOpportunity?.root.id), {
											replace: true,
									  })
									: navigate(getLearnOpportunityPath(learnOpportunity.id), { replace: true });
							}}
							className="p-2"
							icon="pi pi-times"
						/>
					</div>
				</Header>
			)}
		</div>
	);
}

/**
 * @generated SignedSource<<f302712a56b29d8784d7bcaec37a3ee1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShowAnswersButton_LearnOpportunityFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly showAnswerElements?: ReadonlyArray<{
      readonly answerOptions?: ReadonlyArray<{
        readonly answer: string;
        readonly isCorrect: boolean;
      }>;
      readonly elementType: ElementTypeV2;
      readonly id: string;
      readonly question?: string;
    }> | null;
  };
  readonly " $fragmentType": "ShowAnswersButton_LearnOpportunityFragment";
};
export type ShowAnswersButton_LearnOpportunityFragment$key = {
  readonly " $data"?: ShowAnswersButton_LearnOpportunityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowAnswersButton_LearnOpportunityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowAnswersButton_LearnOpportunityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "showAnswerElements",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "elementType",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "question",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AnswerOptionV2",
                      "kind": "LinkedField",
                      "name": "answerOptions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "answer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isCorrect",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "MultipleChoiceElementV2",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "22c3ff4a96d6b263a2d8f2d8b6bb2614";

export default node;

import React from "react";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { StripHtml } from "../../../functions/helpers/StripHtml";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../../infecto-lms-webapp-impl/config";
import { SecondaryButton } from "../../buttons/SecondaryButton";

interface OwnProps {
	isVisible: boolean;
	onHideDialog(): void;
	elements: any;
}

export function ShowAnswersDialog({ isVisible, onHideDialog, elements }: OwnProps) {
	const answersRenderer = (answerOptions: any[]): JSX.Element[] => {
		return answerOptions?.map((answerOption, index) => (
			<Answer correct={answerOption.isCorrect} key={index}>
				{StripHtml(answerOption.answer)}
			</Answer>
		));
	};

	const itemRenderer = (index: number, question: string, answers: JSX.Element[]): JSX.Element => {
		return (
			<div key={index}>
				<span>
					{index + 1}. Frage: {StripHtml(question)}
				</span>
				<br />
				<div className="ml-4 mb-2">{answers}</div>
				<br />
			</div>
		);
	};

	const contentRenderer = elements.map((element: any, index: number) =>
		itemRenderer(index, element.question, answersRenderer(element.answerOptions)),
	);

	const footer = () => {
		return (
			<div>
				<SecondaryButton label="Schließen" onClick={onHideDialog} />
			</div>
		);
	};

	return (
		<StyledDialog
			header="Antworten ansehen"
			visible={isVisible}
			footer={footer}
			onHide={onHideDialog}
		>
			{contentRenderer}
		</StyledDialog>
	);
}

const Answer = styled.p<{ correct: boolean }>`
	color: ${(props) => (props.correct ? LMS_WEBAPP_CONFIG_IMPL.brandColor : "#495057")};
`;

const StyledDialog = styled(Dialog)`
	width: 50%;
	@media (max-width: 768px) {
		width: 90%;
	}
`;

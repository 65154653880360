import React, { Suspense } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { SearchNodesScreen_Query } from "@generated/SearchNodesScreen_Query.graphql";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { SearchBar } from "../../infecto-lms-webapp/components/search/SearchBar.component";
import { SearchOrderV2 } from "../../infecto-lms-webapp/components/search/SearchOrder.component";
import { SearchResults } from "../../infecto-lms-webapp/components/search/SearchResults.component";
import { SearchFilterButton } from "../../infecto-lms-webapp/components/search/SearchFilterButton.component";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { SearchContextProvider } from "../../infecto-lms-webapp/context/search-context/SearchContextProvider";
import { SearchLearnOpportunitiesFormatTypeInput } from "@generated/SearchResults_Query.graphql";
import { Loader } from "../../infecto-lms-webapp/components/loading.component";
import { ProjectSearchFilters } from "../../infecto-lms-webapp-impl/components/relay/ProjectSearchFilters";
import { defineDynamicHeightForContainers } from "../../infecto-lms-webapp/hooks/defineDynamicHeightForContainers";

const QUERY = graphql`
	query SearchNodesScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}

		...ProjectSearchFilters_QueryFragment
		...SearchFilterButton_QueryFragment
	}
`;

interface OwnProps {
	formatType?: SearchLearnOpportunitiesFormatTypeInput[];
}

export function SearchNodesScreen({ formatType }: OwnProps) {
	usePageTitle("Suche");

	const query = useLazyLoadQuery<SearchNodesScreen_Query>(QUERY, {});

	return (
		<SearchContextProvider formatType={formatType}>
			<DefaultContainer
				collapsed={false}
				screenName="search"
				userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
				rightSideSlot={
					<div className="hidden lg:block bg-white">
						<ProjectSearchFilters queryFragmentRef={query} />
					</div>
				}
			>
				<MainContainerWithTopBar height={defineDynamicHeightForContainers()}>
					<h1>{"Module"}</h1>
					<p className="text-base">Hier finden Sie eine Übersicht aller Fortbildungsangebote.</p>

					<SearchBar />
					<div className="flex flex-row pl-0 pr-0 spread col-12 mb-3">
						<div className="lg:hidden">
							<SearchFilterButton queryFragmentRef={query} />
						</div>
						<div className="flex-grow-1">
							<SearchOrderV2 />
						</div>
					</div>

					<Suspense fallback={<Loader />}>
						<SearchResults />
					</Suspense>
				</MainContainerWithTopBar>
			</DefaultContainer>
		</SearchContextProvider>
	);
}

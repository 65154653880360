import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { getLearnOpportunityPath } from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { getContentSubmissionPath } from "../../infecto-lms-webapp-impl/router/routes/auth/learn.routes";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import styled from "styled-components";
import { NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$key } from "@generated/NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment.graphql";
import { NavigateDirectToQuestionsAdvanceTreeButton_StartTreeMutation } from "@generated/NavigateDirectToQuestionsAdvanceTreeButton_StartTreeMutation.graphql";
import { NavigateDirectToQuestionsAdvanceTreeButton_StartContentNodeMutation } from "@generated/NavigateDirectToQuestionsAdvanceTreeButton_StartContentNodeMutation.graphql";
import { NavigateDirectToQuestionsAdvanceContentButton } from "./NavigateDirectToQuestionsAdvanceContentButton";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";
import { Label } from "../../infecto-lms-webapp/components/buttons/Label";

const TREE_ADVANCEMENT_RESULT_FRAGMENT = graphql`
	fragment NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		shortDescription
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					viewerTreeState {
						kind

						... on CanNotBeStartedViewerTreeState {
							reasonConfigTypes
						}
						... on IsStartedViewerTreeState {
							headContentId
						}
					}
				}
			}
		}
		structureDefinition {
			title
		}
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				contentNodeAdvancementResult {
					status
				}
				extension {
					... on LearnContentExtensionImpl {
						cmePoints
						headFirstElementType
					}
				}
			}
		}
	}
`;

const START_TREE_MUTATION = graphql`
	mutation NavigateDirectToQuestionsAdvanceTreeButton_StartTreeMutation($input: StartTreeInput!) {
		LearnV2 {
			startTree(input: $input) {
				learnOpportunity {
					id
					structureDefinition {
						... on LearnOpportunityRootStructureDefinition {
							viewerTreeState {
								kind

								... on IsStartedViewerTreeState {
									headContentId
								}
							}
						}
					}
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							contentNodeAdvancementResult {
								status
							}
						}
					}
					...NodeScreen_NodeFragment
				}
			}
		}
	}
`;

const START_CONTENT_NODE_MUTATION = graphql`
	mutation NavigateDirectToQuestionsAdvanceTreeButton_StartContentNodeMutation(
		$input: StartContentNodeInput!
	) {
		LearnV2 {
			startContentNode(input: $input) {
				contentSubmission {
					id
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$key;
	showFinished: boolean;
	className?: string;
}

export function NavigateDirectToQuestionsAdvanceTreeButton({
	learnOpportunityFragmentRef,
	showFinished,
	className,
}: OwnProps) {
	const navigate = useNavigate();

	const learnOpportunity =
		useFragment<NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$key>(
			TREE_ADVANCEMENT_RESULT_FRAGMENT,
			learnOpportunityFragmentRef,
		);

	const [startTree, isStartingTree] =
		useMutation<NavigateDirectToQuestionsAdvanceTreeButton_StartTreeMutation>(START_TREE_MUTATION);

	const [startContentNode, isStartingContentNode] =
		useMutation<NavigateDirectToQuestionsAdvanceTreeButton_StartContentNodeMutation>(
			START_CONTENT_NODE_MUTATION,
		);

	return (
		<DivContainer>
			{(() => {
				switch (learnOpportunity.root?.structureDefinition.viewerTreeState?.kind) {
					case "CanBeStarted":
						const startAtContentNodeId =
							(learnOpportunity.typeDefinition.definitionType === "content" &&
								(learnOpportunity.typeDefinition.extension?.cmePoints || -1)) > 0
								? learnOpportunity.id
								: null;
						return (
							<LinkButton
								disabled={isStartingTree || isStartingContentNode}
								className={`bg-primary ${className}`}
								onClick={() => {
									startTree({
										variables: {
											input: {
												nodeId: learnOpportunity.id,
												startAtContentNodeId: startAtContentNodeId,
											},
										},
										onCompleted: (startTreeResponse) => {
											if (
												startTreeResponse.LearnV2.startTree?.learnOpportunity.typeDefinition
													.contentNodeAdvancementResult?.status === "CanBeStarted"
											) {
												startContentNode({
													variables: {
														input: {
															contentNodeId: learnOpportunity.id,
														},
													},
													onCompleted: (startContentNodeResponse) => {
														navigate(
															getContentSubmissionPath(
																startContentNodeResponse.LearnV2.startContentNode?.contentSubmission
																	.id || "",
															),
														);
													},
												});
												return;
											}
											if (
												startTreeResponse.LearnV2.startTree?.learnOpportunity.structureDefinition
													.viewerTreeState?.kind === "IsStarted"
											) {
												navigate(
													getLearnOpportunityPath(
														learnOpportunity.root ? learnOpportunity.root.id : learnOpportunity.id,
													),
												);
											}
										},
									});
								}}
							>
								{" "}
								<Label
									dangerouslySetInnerHTML={{
										__html:
											learnOpportunity.shortDescription &&
											learnOpportunity.shortDescription !== "<div><br></div>"
												? learnOpportunity.shortDescription
												: learnOpportunity.structureDefinition.title,
									}}
								/>
							</LinkButton>
						);
					case "CanNotBeStarted":
						return (
							<LinkButton
								disabled
								className={`bg-primary ${className}`}
								tooltipOptions={{
									showOnDisabled: true,
									position: "bottom",
									mouseTrack: true,
									mouseTrackTop: 0,
								}}
								tooltip={
									learnOpportunity.root.structureDefinition.viewerTreeState.reasonConfigTypes?.find(
										(ct) => ct === "StartTree_SpecificUserStatusesCan",
									)
										? "Ihr Konto ist nicht vollständig freigeschaltet. Bitte beachten Sie die gelben Warnmeldungen auf dieser Seite."
										: "Nicht freigeschaltet"
								}
							>
								{" "}
								<Label
									dangerouslySetInnerHTML={{
										__html:
											learnOpportunity.shortDescription &&
											learnOpportunity.shortDescription !== "<div><br></div>"
												? learnOpportunity.shortDescription
												: learnOpportunity.structureDefinition.title,
									}}
								/>
							</LinkButton>
						);
					case "IsStarted":
						switch (learnOpportunity.typeDefinition.contentNodeAdvancementResult?.status) {
							case "CanBeStarted":
							case "CanBeRestartedAfterPassed":
							case "BlockedByTree":
								return (
									<NavigateDirectToQuestionsAdvanceContentButton
										learnOpportunityId={learnOpportunity.id}
										className={"bg-primary ml-0 md:ml-3"}
									/>
								);
							case "CanBeRestartedAfterFailed":
								break;
							default:
								break;
						}
						break;
					case "IsFinished":
						return (
							showFinished && (
								<LinkButton
									className="p-button-primary bg-primary border-primary"
									onClick={() => navigate(DASHBOARD_PATH)}
									label="Modul abgeschlossen"
								/>
							)
						);
					default:
						// NotVisible, AtHead, NotAtHead, isFinished
						return null;
				}
			})()}
		</DivContainer>
	);
}

const LinkButton = styled(PrimaryButton)`
	display: flex;
	justify-content: center;
	max-width: 400px;
	text-align: center;
	border: none;
	cursor: pointer;
`;

const DivContainer = styled.div`
	max-width: 400px;
`;

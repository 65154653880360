import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { Message } from "primereact/message";
import { DashboardScreen_Query } from "@generated/DashboardScreen_Query.graphql";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { Nodes } from "../../infecto-lms-webapp/components/node/Nodes.component";
import { extractName } from "../../infecto-lms-webapp/components/navigation/ProfileLink.component";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { defineDynamicHeightForContainers } from "../../infecto-lms-webapp/hooks/defineDynamicHeightForContainers";

const DASHBOARD_QUERY = graphql`
	query DashboardScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								firstName
								efnNumber
								country
							}
						}
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
		...Nodes_QueryFragment
	}
`;

export function DashboardScreen() {
	usePageTitle("Dashboard");

	const query = useLazyLoadQuery<DashboardScreen_Query>(DASHBOARD_QUERY, {});

	return (
		<DefaultContainer
			collapsed={false}
			screenName="dashboard"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar height={defineDynamicHeightForContainers()}>
				{query.Viewer.Auth.currentUser?.user.extension.country == "Oesterreich" && (
					<Message
						className="mt-4 mb-4 w-12 p-5"
						severity="warn"
						content={
							<div>
								Willkommen auf der Lernplattform von InfectoPharm Deutschland. Sie können alle
								Bereiche dieser Website nutzen. Wenn Sie zertifizierte Module abschließen, haben Sie
								nach Bestehen die Möglichkeit, sich ein Zertifikat herunterzuladen und dieses bei
								der Österreichischen Ärztekammer einzureichen. Eine automatische Meldung der Punkte
								findet nicht statt.
							</div>
						}
					/>
				)}
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />

				<h1>Hallo {extractName(query.Viewer.Auth.currentUser?.user.extension)}!</h1>

				<p className="text-base">
					Willkommen auf der neuen Lernplattform "Wissen wirkt." Hier finden Sie aktuelle und
					begonnene Lernmodule, im Menü unter "alle Module" steht Ihnen das gesamte Angebot an
					Webinaren, Heften und Podcasts zur Verfügung. Ihre Zertifikate finden Sie unter "meine
					Lernerfahrungen". Viel Spaß beim Entdecken und Punktesammeln!
				</p>

				<h2 className="font-bold">aktuelle Module</h2>

				<Nodes queryFragmentRef={query} />
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
}

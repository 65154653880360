/**
 * @generated SignedSource<<b9345ae8311305acff3e2256650ed2f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetCommunicationSettingsInput = {
  clientMutationId?: string | null;
  sendExpirationNoticeEmails: boolean;
  sendExpirationNoticeNotifications: boolean;
  sendInformRetryEmails: boolean;
  sendInformRetryNotifications: boolean;
};
export type CommunicationSettingsScreen_SetCommunicationSettingsMutation$variables = {
  input: SetCommunicationSettingsInput;
};
export type CommunicationSettingsScreen_SetCommunicationSettingsMutation$data = {
  readonly Viewer: {
    readonly Email: {
      readonly setCommunicationSettings: {
        readonly communicationSettings: {
          readonly " $fragmentSpreads": FragmentRefs<"CommunicationSettingsScreen_CommunicationSettingsFragment">;
        };
      } | null;
    };
  };
};
export type CommunicationSettingsScreen_SetCommunicationSettingsMutation = {
  response: CommunicationSettingsScreen_SetCommunicationSettingsMutation$data;
  variables: CommunicationSettingsScreen_SetCommunicationSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunicationSettingsScreen_SetCommunicationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerMutationType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailViewerMutationSchema",
            "kind": "LinkedField",
            "name": "Email",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetCommunicationSettingsPayload",
                "kind": "LinkedField",
                "name": "setCommunicationSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommunicationSettings",
                    "kind": "LinkedField",
                    "name": "communicationSettings",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CommunicationSettingsScreen_CommunicationSettingsFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunicationSettingsScreen_SetCommunicationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerMutationType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailViewerMutationSchema",
            "kind": "LinkedField",
            "name": "Email",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetCommunicationSettingsPayload",
                "kind": "LinkedField",
                "name": "setCommunicationSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommunicationSettings",
                    "kind": "LinkedField",
                    "name": "communicationSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sendExpirationNoticeEmails",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sendExpirationNoticeNotifications",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sendInformRetryEmails",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sendInformRetryNotifications",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b07444f241fd705f3efcd7a3bea2d3e6",
    "id": null,
    "metadata": {},
    "name": "CommunicationSettingsScreen_SetCommunicationSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation CommunicationSettingsScreen_SetCommunicationSettingsMutation(\n  $input: SetCommunicationSettingsInput!\n) {\n  Viewer {\n    Email {\n      setCommunicationSettings(input: $input) {\n        communicationSettings {\n          ...CommunicationSettingsScreen_CommunicationSettingsFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment CommunicationSettingsScreen_CommunicationSettingsFragment on CommunicationSettings {\n  id\n  sendExpirationNoticeEmails\n  sendExpirationNoticeNotifications\n  sendInformRetryEmails\n  sendInformRetryNotifications\n}\n"
  }
};
})();

(node as any).hash = "7a379179e1dc80930238f9ff753c0cbc";

export default node;

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { ProjectNodeIcons_NodeFragment$key } from "@generated/ProjectNodeIcons_NodeFragment.graphql";
import { useFragment } from "react-relay";
import { IconButton } from "../../infecto-lms-webapp/components/node/NodeIcons.component";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment ProjectNodeIcons_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							elementTypes
						}
					}
				}
			}
		}
	}
`;
interface OwnProps {
	learnOpportunityFragmentRef: ProjectNodeIcons_NodeFragment$key;
}
export const ProjectNodeIcons = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const learnOpportunity = useFragment<ProjectNodeIcons_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const hasPodcast =
		learnOpportunity.structureDefinition.extension?.reducedDataInTree?.elementTypes.includes(
			"podcast",
		);
	return (
		<div>
			<IconButton
				disabled={!hasPodcast}
				label=""
				className="p-button-text"
				icon="icon-micro"
				tooltip={`Enthält ${!hasPodcast ? "keine" : ""} Podcast-Elemente`}
				tooltipOptions={{ position: "left", showOnDisabled: true }}
			/>
		</div>
	);
};

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { PointsPoolDisplay_UserFragment$key } from "@generated/PointsPoolDisplay_UserFragment.graphql";

const USER_FRAGMENT = graphql`
	fragment PointsPoolDisplay_UserFragment on User {
		extension {
			... on InfectopharmUserExtensionImpl {
				pointsInPoolV2(pointPoolId: "UG9pbnRQb29sOmNtZS1wb2ludHM=", limitByDays: 1825) {
					points
					pointPoolName
				}
			}
		}
	}
`;

interface OwnProps {
	userFragment: PointsPoolDisplay_UserFragment$key;
}

export function PointsPoolDisplay({ userFragment }: OwnProps) {
	const user = useFragment<PointsPoolDisplay_UserFragment$key>(USER_FRAGMENT, userFragment);

	return (
		<span>
			{user.extension.pointsInPoolV2?.points} {user.extension.pointsInPoolV2?.pointPoolName}
		</span>
	);
}

import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Message } from "primereact/message";
import { TreeAdvancementMessage_NodeFragment$key } from "@generated/TreeAdvancementMessage_NodeFragment.graphql";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment TreeAdvancementMessage_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				viewerTreeState {
					kind
				}
			}
		}
	}
`;

interface OwnProps {
	nodeFragment: TreeAdvancementMessage_NodeFragment$key;
}

export function TreeAdvancementMessage({ nodeFragment }: OwnProps) {
	const node = useFragment<TreeAdvancementMessage_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		nodeFragment,
	);

	switch (node.structureDefinition.viewerTreeState?.kind) {
		case "IsFinished":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>Hinweis:</strong> Sie haben dieses Modul bereits erfolgreich absolviert.
						</div>
					}
				/>
			);
		default:
			return null;
	}
}

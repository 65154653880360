import { LmsWebAppConfiguration } from "../infecto-lms-webapp/config";

export const LMS_WEBAPP_CONFIG_IMPL: LmsWebAppConfiguration = {
	brandId: "QnJhbmQ6QnJhbmQ6MWVkZDQ2YzYtODkwMC00YTI4LTk1MTEtNTllYzgyNzVhNDg0",
	brandDomain: ".wissenwirkt.com",
	browserTabTitle: "Wissen wirkt. -",
	browserTabDescription: "Fortbildungen für Ärztinnen/Ärzte, Apothekerinnen/Apotheker, Hebammen",
	multipleChoiceDownloadButtonText: "Übersicht CME-Fragen",
	includeSearchbarInHeader: true,
	showLogoInSideBar: true,
	alwaysShowLogoInMenuTopBar: false,
	widthLogoLoginPrompt: 150,
	privacyLink: "https://www.infectopharm.com/datenschutz/",
	brandColor: "#4ba540",
	fontFamily: "Lato, sans-serif",
};

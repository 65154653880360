/**
 * @generated SignedSource<<332e4b2e1e48a6f0423dca8daaff651d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectNodeTopBar_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly root: {
    readonly id: string;
  } | null;
  readonly typeDefinition: {
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentType": "ProjectNodeTopBar_LearnOpportunityV2Fragment";
};
export type ProjectNodeTopBar_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: ProjectNodeTopBar_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectNodeTopBar_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectNodeTopBar_LearnOpportunityV2Fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "containedElementTypes",
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "c6b3eb47afbf1fa9f4995998817798c8";

export default node;

/**
 * @generated SignedSource<<d51f57cbaa08c33b88e30659d1605862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigateDirectToQuestionsAdvanceContentButton_Query$variables = {
  learnOpportunityId: string;
};
export type NavigateDirectToQuestionsAdvanceContentButton_Query$data = {
  readonly node: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment">;
  } | null;
};
export type NavigateDirectToQuestionsAdvanceContentButton_Query = {
  response: NavigateDirectToQuestionsAdvanceContentButton_Query$data;
  variables: NavigateDirectToQuestionsAdvanceContentButton_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "learnOpportunityId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "learnOpportunityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigateDirectToQuestionsAdvanceContentButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment"
              }
            ],
            "type": "LearnOpportunityV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigateDirectToQuestionsAdvanceContentButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "structureDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "typeDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "definitionType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "contentNodeAdvancementResult",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reasonConfigTypes",
                                "storageKey": null
                              }
                            ],
                            "type": "CanNotBeStartedContentNodeAdvancementResult",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "activeContentSubmissionId",
                                "storageKey": null
                              }
                            ],
                            "type": "ContinueContentNodeAdvancementResult",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "configResults",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isRestartIfFailedContentConfigResult"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "canBeRestarted",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "blockedUntil",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "containedElementTypes",
                        "storageKey": null
                      }
                    ],
                    "type": "LearnOpportunityELearningContentTypeDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LearnOpportunityV2",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8930fe257fd49bcf480ac425cfcd6ed",
    "id": null,
    "metadata": {},
    "name": "NavigateDirectToQuestionsAdvanceContentButton_Query",
    "operationKind": "query",
    "text": "query NavigateDirectToQuestionsAdvanceContentButton_Query(\n  $learnOpportunityId: ID!\n) {\n  node(id: $learnOpportunityId) {\n    __typename\n    id\n    ... on LearnOpportunityV2 {\n      id\n      ...NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment\n    }\n  }\n}\n\nfragment NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment on LearnOpportunityV2 {\n  id\n  shortDescription\n  structureDefinition {\n    __typename\n    title\n  }\n  typeDefinition {\n    __typename\n    ... on LearnOpportunityELearningContentTypeDefinition {\n      definitionType\n      contentNodeAdvancementResult {\n        __typename\n        status\n        ... on CanNotBeStartedContentNodeAdvancementResult {\n          reasonConfigTypes\n        }\n        ... on ContinueContentNodeAdvancementResult {\n          activeContentSubmissionId\n        }\n        ... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {\n          status\n          configResults {\n            __typename\n            configType\n            __isRestartIfFailedContentConfigResult: __typename\n            canBeRestarted\n            ... on NegativeBlockPermanentlyWhenRewardedRestartIfFailedContentConfigResult {\n              canBeRestarted\n            }\n            ... on NegativeBlockTemporarilyWhenRewardedRestartIfFailedContentConfigResult {\n              blockedUntil\n            }\n          }\n        }\n      }\n      containedElementTypes\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09d905e86eecfa1edc4d1f39dfdfb3fe";

export default node;

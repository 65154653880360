import React from "react";
import styled from "styled-components";
import { classNames } from "primereact/utils";
import { NavLink } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useTypedSelector } from "../../infecto-lms-webapp/redux/redux-store";
import { NodeTagList } from "../../infecto-lms-webapp/components/node/NodeTagList.component";
import { NodeCertificateDisplay } from "../../infecto-lms-webapp/components/node/NodeCertificateDisplay.component";
import { DateTimeDisplay } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { DurationDisplay } from "../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { NodeIcons } from "../../infecto-lms-webapp/components/node/NodeIcons.component";
import { DASHBOARD_PATH } from "../router/routes/auth/dashboard.routes";
import { getLearnOpportunityPath } from "../router/routes/auth/nodes.routes";
import { NodeCard_NodeFragment$key } from "@generated/NodeCard_NodeFragment.graphql";
import { NodeCard_LandingPageNodeFragment$key } from "@generated/NodeCard_LandingPageNodeFragment.graphql";
import { selectAuthSlice } from "../../infecto-lms-webapp/redux/slices/auth.slice";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeCard_NodeFragment on LearnOpportunityV2 {
		id
		shortDescription
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						lengthInSecondsOverride
						isVisibleOnlyForAdmins
						issuedCertificates {
							issuedAt
							fileRef {
								url
							}
						}
						reducedDataInTree {
							cmePoints
						}
						firstLearnOpportunityIdWithMultipleChoiceElement
						configConsequences {
							rewardExpired
						}
					}
				}
				icon {
					url
				}
				progressPercentage
				...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				...NodeCertificateDisplay_NodeRootStructureDefinitionFragment
			}
		}
		...NodeIcons_NodeFragment
	}
`;

const LANDING_PAGE_FRAGMENT = graphql`
	fragment NodeCard_LandingPageNodeFragment on LearnOpportunityV2 {
		id
		shortDescription
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						configConsequences {
							rewardExpired
						}
					}
				}
				icon {
					url
				}
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							cmePoints
						}
					}
				}
				...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
			}
		}
		...NodeIcons_NodeFragment
	}
`;

const IconWrapper = styled.div<{ isFeatured: boolean }>`
	${(props) =>
		props.isFeatured ? "border-top: none;" : "border-top: 1px solid; border-bottom: 1px solid;"}
`;

const LearnOpportunityCardWrapper = styled.div<{ isVisibleOnlyForAdmins: boolean }>`
	${(props) =>
		props.isVisibleOnlyForAdmins ? "border-bottom: 8px solid #5f6bfb;" : "border-bottom: none;"}
	height: 100%;
	display: flex;
`;

const Icon = styled.img<{ isFeatured: boolean }>`
	max-width: ${(props) => (props.isFeatured ? "120px" : "100px")};
`;

interface OwnProps {
	isFeatured?: boolean;
	className?: string;
	learnOpportunityFragmentRef?: NodeCard_NodeFragment$key;
	landingPageFragmentRef?: NodeCard_LandingPageNodeFragment$key;
	isLandingPage: boolean;
}

export function NodeCard({
	isFeatured,
	className,
	learnOpportunityFragmentRef,
	isLandingPage,
	landingPageFragmentRef,
}: OwnProps) {
	const authState = useTypedSelector(selectAuthSlice);
	const isLoggedIn = authState.isLoggedIn;

	const landingPage = useFragment<NodeCard_LandingPageNodeFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef!,
	);
	const learnOpportunityLoggedIn = useFragment<NodeCard_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef!,
	);

	const learnOpportunity = learnOpportunityLoggedIn || landingPage;

	const redirectToLearnOpportunityAfterLogin = () => {
		localStorage.setItem("landingPage", "true");
		localStorage.setItem("learnOpportunityId", learnOpportunity.id);
	};

	const numIssuedCertificates =
		(!isLandingPage &&
			learnOpportunity.structureDefinition.extension?.issuedCertificates! &&
			learnOpportunity.structureDefinition.extension.issuedCertificates?.length) ||
		0;

	return (
		<LearnOpportunityCardWrapper
			onClick={redirectToLearnOpportunityAfterLogin}
			isVisibleOnlyForAdmins={
				learnOpportunity.structureDefinition.extension?.isVisibleOnlyForAdmins as boolean
			}
			className={`p-card p-0 relative flex-column  ${className} `}
		>
			<div
				className={`w-12 pb-3 h-full flex flex-column ${classNames({
					"md:flex-column": !isFeatured,
					"md:flex-row": isFeatured,
				})}`}
			>
				<NavLink to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}>
					<div
						className={`pt-3 pl-3 pr-3 ${classNames({
							"flex flex-row-reverse align-items-start justify-content-between": !isFeatured,
							"pr-3": isFeatured,
						})}`}
					>
						{" "}
						<NodeTagList
							className="ml-auto align-items-end mb-3 flex-wrap justify-content-end"
							learnOpportunityRootStructureDefinitionFragmentRef={
								learnOpportunity.structureDefinition
							}
						/>
						{learnOpportunity.structureDefinition.icon?.url && (
							<Icon
								isFeatured={isFeatured || false}
								alt={learnOpportunity.structureDefinition.title}
								src={learnOpportunity.structureDefinition.icon.url}
							/>
						)}
					</div>
				</NavLink>
				<div
					className={`flex pl-3 pr-3 flex-grow-1 flex-column justify-content-between ${classNames({
						"pt-3": !isFeatured,
					})}`}
				>
					<NavLink to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}>
						{!isLandingPage && learnOpportunityFragmentRef && (
							<IconWrapper isFeatured={isFeatured || false} className="flex mb-3 border-300">
								<NodeCertificateDisplay
									learnOpportunityRootStructureDefinitionFragmentRef={
										learnOpportunity.structureDefinition
									}
								/>
								<NodeIcons className="ml-auto" learnOpportunityFragmentRef={learnOpportunity} />
							</IconWrapper>
						)}
						<h3 className="mt-0">{learnOpportunity.structureDefinition.title}</h3>
						{learnOpportunity.shortDescription && (
							<div dangerouslySetInnerHTML={{ __html: learnOpportunity.shortDescription }} />
						)}
						{!isLandingPage && (
							<div className="flex flex-grow-1 align-items-center mt-3 mb-3">
								<hr className="w-12" />
							</div>
						)}
						{isLandingPage && <hr className="w-12 mb-3 mt-3" />}
					</NavLink>
					{!isLandingPage && numIssuedCertificates > 0 ? (
						<div className="flex align-items-center">
							<div>
								Zertifikat erhalten am{" "}
								<DateTimeDisplay
									hideTimezone
									value={
										learnOpportunity.structureDefinition.extension?.issuedCertificates![0]
											.issuedAt as string
									}
								/>
							</div>

							<div className="ml-auto flex align-items-center">
								{(learnOpportunity.structureDefinition.extension?.reducedDataInTree?.cmePoints ||
									-1) > 0 ? (
									<>
										{/*TODO: make it paedia comaptible*/}
										{/*learnOpportunity.points[0].poolName*/}CME-Punkte{" "}
										<span className="ml-2 text-lg font-bold">
											{learnOpportunity.structureDefinition.extension?.reducedDataInTree?.cmePoints}
										</span>
									</>
								) : (
									""
								)}
							</div>
						</div>
					) : (
						<DurationAndButtonWrapper className="flex align-items-center">
							<NavLink
								to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}
							>
								<div>
									Dauer:{" "}
									<DurationDisplay
										duration={
											learnOpportunity.structureDefinition.extension
												?.lengthInSecondsOverride as number
										}
									/>
								</div>
							</NavLink>
							{!isLandingPage ? (
								<div className="ml-auto flex align-items-center">
									{learnOpportunity.structureDefinition.extension?.configConsequences
										?.rewardExpired || false ? (
										"Übungsmodul"
									) : (learnOpportunity.structureDefinition.extension?.reducedDataInTree
											?.cmePoints || -1) > 0 ? (
										<div>
											<NavLink to={getLearnOpportunityPath(learnOpportunity.id)}>
												<CmeButtonOnlyDesign className="ml-2">
													CME-Punkte
													<span className="ml-2 text-lg font-bold">
														{
															learnOpportunity.structureDefinition.extension?.reducedDataInTree
																?.cmePoints
														}
													</span>
												</CmeButtonOnlyDesign>
											</NavLink>
										</div>
									) : (
										""
									)}
								</div>
							) : (
								<div className="ml-auto flex align-items-center">
									{learnOpportunity.structureDefinition.extension?.configConsequences
										?.rewardExpired || false ? (
										"Übungsmodul"
									) : (learnOpportunity.structureDefinition.extension?.reducedDataInTree
											?.cmePoints || -1) > 0 ? (
										<>
											{" "}
											<NavLink
												to={
													isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH
												}
											>
												<CmeButtonOnlyDesign className="ml-2">
													{/*TODO: make it paedia comaptible*/}
													{/*learnOpportunity.points[0].poolName*/}CME-Punkte
													<span className="ml-2 text-lg font-bold">
														{
															learnOpportunity.structureDefinition.extension?.reducedDataInTree
																?.cmePoints
														}
													</span>
												</CmeButtonOnlyDesign>
											</NavLink>
										</>
									) : (
										""
									)}
								</div>
							)}
						</DurationAndButtonWrapper>
					)}
				</div>
			</div>
			{!isLandingPage && numIssuedCertificates > 0 && (
				<a
					target="_blank"
					rel="noopener noreferrer"
					download
					href={
						learnOpportunity.structureDefinition.extension?.issuedCertificates![0].fileRef?.url!
					}
				>
					<div
						className="pl-3 pr-3 pb-3 pt-3 flex"
						style={{ backgroundColor: "rgba(85,177,74, 0.1)" }}
					>
						<h4 className="text-primary m-0">Zertifikat herunterladen</h4>
						<div className="ml-auto">
							<span className="font-bold pi pi-cloud-download text-primary" />
						</div>
					</div>
				</a>
			)}
		</LearnOpportunityCardWrapper>
	);
}

const CmeButtonOnlyDesign = styled(PrimaryButton)`
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	cursor: pointer;
	padding: 5px;
`;

const DurationAndButtonWrapper = styled.div`
	min-height: 32px;
`;

import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { AllMessagesScreen } from "../../../../app/screens/AllMessages.screen";

export const MESSAGING_SCREEN_PATH = "/nachrichten";
export const MessagingRoutes: PrivateRouteDefinition[] = [
	{
		path: MESSAGING_SCREEN_PATH,
		component: <AllMessagesScreen />,
	},
];

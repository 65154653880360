import React from "react";
import { defineDynamicHeightForContainers } from "../../infecto-lms-webapp/hooks/defineDynamicHeightForContainers";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { graphql } from "babel-plugin-relay/macro";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { useLazyLoadQuery } from "react-relay";
import { ErrorPageComponent } from "../../infecto-lms-webapp/components/ErrorPage.component";
import { PageNotFoundScreen_Query } from "@generated/PageNotFoundScreen_Query.graphql";

const PAGENOTFOUND_QUERY = graphql`
	query PageNotFoundScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								firstName
								efnNumber
								country
							}
						}
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}
	}
`;

export const PageNotFoundScreen = () => {
	usePageTitle("PageNotFound");

	const query = useLazyLoadQuery<PageNotFoundScreen_Query>(PAGENOTFOUND_QUERY, {});

	return (
		<DefaultContainer
			collapsed={false}
			screenName="dashboard"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar height={defineDynamicHeightForContainers()}>
				<ErrorPageComponent
					errorType={"404"}
					title={"Die gewünschte Seite wurde nicht gefunden."}
					description={
						"Entschuldigung, die Seite, die Sie suchen, konnte nicht gefunden werden oder wurde entfernt."
					}
				/>
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
};

import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { PageNotFoundScreen } from "../../../../app/screens/PageNotFoundScreen";

export const PageNotFoundRoutes: PrivateRouteDefinition[] = [
	{
		path: "*",
		component: <PageNotFoundScreen />,
	},
];

import React, { useContext } from "react";
import { SearchContext } from "../../infecto-lms-webapp/context/search-context/SearchContextProvider";
import { SearchLearnOpportunitiesFormatTypeInput } from "@generated/SearchResults_Query.graphql";
import { StyledCheckbox } from "./relay/ProjectSearchFilters";
import { LMS_WEBAPP_CONFIG_IMPL } from "../config";
import { calculateButtonStateColor } from "../../infecto-lms-webapp/functions/helpers/calculateButtonStateColor";

export const ProjectExtendFormatFilters = () => {
	const { filters, setFilters } = useContext(SearchContext);
	return (
		<div className="flex pt-3 pb-3">
			<label className="flex align-items-center flex-grow-1">
				<span className="font-bold mr-2">
					<span className="icon-micro mr-2" />
					Podcast
				</span>
				<StyledCheckbox
					colorPress={calculateButtonStateColor().pressColor}
					color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
					className="ml-auto"
					checked={filters.formatTypes?.includes("Podcast")}
					onChange={(e) =>
						setFilters({
							...filters,
							formatTypes: [
								...(filters.formatTypes?.filter((t) => t !== "Podcast") || []),
								...(e.checked ? ["Podcast" as SearchLearnOpportunitiesFormatTypeInput] : []),
							],
						})
					}
				/>
			</label>
		</div>
	);
};

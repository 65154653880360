import React from "react";
import { Message } from "primereact/message";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";
import { COMMUNICATION_SETTINGS_SCREEN_PATH } from "../router/routes/auth/communication-settings.routes";

interface OwnProps {
	user: any;
}
export const ProjectUserStatusMessageComponent = ({ user }: OwnProps) => {
	switch (user.globalUserStatus) {
		case "noProcess":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Konto ist nicht finalisiert. Sie können keine
							Fortbildungen durchführen. Bitte finalisieren Sie Ihr Konto über{" "}
							<strong>
								<a className="text-primary" href="https://identity.infectopharm.com">
									diesen Link.
								</a>
							</strong>
						</div>
					}
				/>
			);

		case "efnNeeded":
			if (/modul/.test(window.location.pathname)) {
				return (
					<Message
						className="mt-4 mb-4 w-12 p-5"
						severity="warn"
						content={
							<div>
								<strong>ACHTUNG:</strong>{" "}
								{`Sie können Punkte sammeln und an der Lernerfolgskontrolle
								teilnehmen sobald uns Ihre ${
									user.country === "Oesterreich"
										? "Österreichische Ärztekammer-Nummer (ÖÄK-Arztnummer)"
										: "Elektronische Fortbildungsnummer (EFN) der Ärztekammer"
								}
								vorliegt. Bitte tragen Sie diese in Ihrem`}{" "}
								<NavLink className="navbar-item" to={COMMUNICATION_SETTINGS_SCREEN_PATH}>
									<Button label="Profil" className="p-0 p-button-text text-primary" />
								</NavLink>{" "}
								ein.
							</div>
						}
					/>
				);
			}

			return <></>;

		case "doccheck":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Nutzerstatus erlaubt keine Teilnahme an der
							Lernerfolgskontrolle. Bitte registrieren Sie sich unter{" "}
							<strong>
								<a className="text-primary" href="https://identity.infectopharm.com">
									identity.infectopharm.com
								</a>
							</strong>{" "}
							oder melden Sie sich mit Ihren InfectoPharm-Zugangsdaten an.
						</div>
					}
				/>
			);
		case "initialValidation":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Konto wird derzeit geprüft. Dies kann bis zu 7 Tagen
							dauern. Währenddessen können Sie keine zertifizierten Fortbildungen durchführen.
							Nicht-zertifizierte Fortbildungen können Sie normal durchführen.
						</div>
					}
				/>
			);
		case "changeInProgress":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Nach Änderung Ihrer Dienst-Adressdaten wird Ihre Adresse
							geprüft. Dies kann bis zu 7 Tagen dauern. Währenddessen können Sie keine
							zertifizierten Fortbildungen durchführen. Nicht-zertifizierte Fortbildungen können Sie
							normal durchführen.
						</div>
					}
				/>
			);
		case "inactive":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> In Ihrem Benutzerkonto ist derzeit keine aktive
							Dienstadresse hinterlegt, deshalb ist die Funktionalität Ihres Kontos eingeschränkt.{" "}
							<span className="text-primary hover:underline">
								<a href="https://identity.infectopharm.com">Dienstadresse überprüfen</a>
							</span>
						</div>
					}
				/>
			);
		default:
			return null;
	}
};

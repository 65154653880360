import React, { useState } from "react";
import styled from "styled-components";
import AppStoreIcon from "../assets/apple-store-button.png";
import PlayStoreIcon from "../assets/google-play-button.png";
import { getCookie, setCookie } from "../functions/cookie-utils";
import { ProjectLogoComponent } from "../../infecto-lms-webapp-impl/components/ProjectLogo.component";

const AppStoreImage = styled.img`
	width: 100%;
	max-width: 150px;
	max-height: 50px;
`;

const AdContainer = styled.div`
	position: absolute;
	z-index: 99;
	background-color: #338638;
	top: 0;
	left: 0;
	width: 100%;
	justify-content: center;

	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
		0 1px 3px 0 rgb(0 0 0 / 12%);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	@media screen and (min-width: 1240px) {
		display: none !important;
	}

	h2 {
		color: white;
	}

	p {
		color: white;
	}
`;

const InnerContainer = styled.div`
	max-width: 768px;
	display: flex;
	align-items: center;
`;

const CloseButton = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	color: white;
`;

export function AppAd() {
	const [isVisible, setVisible] = useState(!getCookie("acknowledge-app-ad"));

	return (
		<AdContainer className="p-5" style={{ display: isVisible ? "flex" : "none" }}>
			<InnerContainer className="flex-wrap">
				<ProjectLogoComponent className="mr-5 mb-2" styles={{ maxWidth: 115, width: "100%" }} />
				<div>
					<h2 className="m-0">WissenWirkt als App auf Ihrem Mobiltelefon</h2>
					<p className="mt-1">
						Laden Sie sich die WissenWirkt App für ein besseres Erlebnis auf iPhone oder Android.
					</p>
					<div className="flex flex-wrap">
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="mr-2"
							href="https://apps.apple.com/de/app/wissen-wirkt/id1614299678"
						>
							<AppStoreImage alt="WissenWirkt im App Store" src={AppStoreIcon} />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://play.google.com/store/apps/details?id=com.infectopharm.lms.app"
						>
							<AppStoreImage alt="WissenWirkt im Play Store" src={PlayStoreIcon} />
						</a>
					</div>
				</div>
			</InnerContainer>
			<CloseButton
				className="p-4"
				onClick={() => {
					setVisible(false);
					setCookie("acknowledge-app-ad", "true");
				}}
			>
				X
			</CloseButton>
		</AdContainer>
	);
}

import { RestartIfFailedContentConfigType } from "@generated/ContentSubmissionEnd_ContentSubmissionFragment.graphql";

export const isPermanentlyBlockedUtil = (
	configResults?: ReadonlyArray<{
		readonly blockedUntil?: string;
		readonly canBeRestarted?: boolean;
		readonly configType: RestartIfFailedContentConfigType;
	}>,
):
	| {
			canBeRestarted?: boolean | undefined;
			configType: RestartIfFailedContentConfigType;
	  }
	| undefined => {
	return configResults?.find(
		(e) =>
			e.configType === "RestartIfFailedContent_BlockPermanently_WhenRewarded" && !e.canBeRestarted,
	);
};

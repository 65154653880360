/**
 * @generated SignedSource<<d8b4e6df63608364e447df44a63c6a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSubmissionStatus = "active" | "failed" | "passed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FailedContentButton_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly status: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly " $fragmentType": "FailedContentButton_ContentSubmissionFragment";
};
export type FailedContentButton_ContentSubmissionFragment$key = {
  readonly " $data"?: FailedContentButton_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FailedContentButton_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedContentButton_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "186e053a061b35ba6bfb8e6313a41ddd";

export default node;

import React, { Suspense } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { UserTreesScreen_Query } from "@generated/UserTreesScreen_Query.graphql";
import { UserStartedTreesStates } from "../../infecto-lms-webapp/components/node/UserStartedTreesStates.component";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { UserFinishedTreeStatesWithCertificate } from "../../infecto-lms-webapp/components/node/UserFinishedTreeStatesWithCertificate.component";
import { UserFinishedTreeStatesWithoutCertificate } from "../../infecto-lms-webapp/components/node/UserFinishedTreeStatesWithoutCertificate.component";
import { PointsPoolDisplay } from "../../infecto-lms-webapp/components/PointsPoolDisplay.component";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { Loader } from "../../infecto-lms-webapp/components/loading.component";
import { Divider } from "primereact/divider";
import { defineDynamicHeightForContainers } from "../../infecto-lms-webapp/hooks/defineDynamicHeightForContainers";

const DASHBOARD_QUERY = graphql`
	query UserTreesScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								efnNumber
							}
						}
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
						...PointsPoolDisplay_UserFragment
					}
				}
			}
		}
	}
`;

export function UserTreesScreen() {
	usePageTitle("Meine Lernerfahrungen");

	const query = useLazyLoadQuery<UserTreesScreen_Query>(
		DASHBOARD_QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<DefaultContainer
			collapsed={false}
			screenName="learn-states"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar height={defineDynamicHeightForContainers()}>
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />

				<h1>
					<PointsPoolDisplay userFragment={query.Viewer.Auth.currentUser?.user!} />
				</h1>
				<p className="text-base">
					Hier finden Sie alle Module, die Sie begonnen oder abgeschlossen haben sowie Ihre
					erworbenen Zertifikate zum Herunterladen.
				</p>

				<Divider align="left">
					<div className="inline-flex align-items-center">
						{/*<i className="pi pi-user mr-2"></i>*/}
						<h2>Gestartete Module</h2>
					</div>
				</Divider>
				<Suspense fallback={<Loader />}>
					<UserStartedTreesStates />
				</Suspense>

				<Divider align="left">
					<div className="inline-flex align-items-center">
						<h2>Abgeschlossene Module (mit Zertifikat)</h2>
					</div>
				</Divider>
				<Suspense fallback={<Loader />}>
					<UserFinishedTreeStatesWithCertificate />
				</Suspense>

				<Divider align="left">
					<div className="inline-flex align-items-center">
						<h2>Abgeschlossene Module (ohne Zertifikat)</h2>
					</div>
				</Divider>
				<Suspense fallback={<Loader />}>
					<UserFinishedTreeStatesWithoutCertificate />
				</Suspense>
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
}

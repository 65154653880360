/**
 * @generated SignedSource<<041c098cd4b0c311230c01a55f093c04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
export type StartContentConfigType = "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "StartContent_SpecificUserStatusesCan" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeContainer_LearnOpportunityV2Fragment$data = {
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly isRewardedTo: string | null;
          readonly restartTriesCount: number | null;
          readonly rewardExpired: boolean;
          readonly showAnswerExpired: boolean;
        };
      };
      readonly icon?: {
        readonly url: string | null;
      } | null;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"NodeTagList_LearnOpportunityRootStructureDefinitionFragment">;
    };
    readonly typeDefinition: {
      readonly children?: ReadonlyArray<{
        readonly id: string;
        readonly shortDescription: string | null;
        readonly structureDefinition: {
          readonly title: string;
        };
        readonly typeDefinition: {
          readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
          readonly contentNodeAdvancementResult?: {
            readonly activeContentSubmissionId?: string;
            readonly configResults?: ReadonlyArray<{
              readonly blockedUntil?: string;
              readonly canBeRestarted: boolean;
              readonly configType: RestartIfFailedContentConfigType;
            }>;
            readonly reasonConfigTypes?: ReadonlyArray<StartContentConfigType>;
            readonly status: ContentNodeAdvancementStatus;
          };
          readonly definitionType: TypeDefinitionType;
        };
        readonly " $fragmentSpreads": FragmentRefs<"ProjectContentButtonCME_LearnOpportunityFragment">;
      }>;
      readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      readonly definitionType: TypeDefinitionType;
    };
    readonly " $fragmentSpreads": FragmentRefs<"NodeContentElementsList_NodeFragment">;
  } | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly extension?: {
      readonly cmePoints?: number | null;
      readonly lengthInSecondsOverride?: number | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentHeftDisplay_LearnOpportunityV2Fragment" | "NodeConfigurationsDisplay_NodeFragment" | "NodeDescription_NodeFragment" | "ProjectNodeTopBar_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "NodeContainer_LearnOpportunityV2Fragment";
};
export type NodeContainer_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: NodeContainer_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeContainer_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containedElementTypes",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeContainer_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/)
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shortDescription",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "typeDefinition",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "contentNodeAdvancementResult",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "status",
                                  "storageKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "reasonConfigTypes",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "CanNotBeStartedContentNodeAdvancementResult",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "activeContentSubmissionId",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "ContinueContentNodeAdvancementResult",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": null,
                                      "kind": "LinkedField",
                                      "name": "configResults",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "configType",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "canBeRestarted",
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "InlineFragment",
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "blockedUntil",
                                              "storageKey": null
                                            }
                                          ],
                                          "type": "NegativeBlockTemporarilyRestartIfFailedContentConfigResult",
                                          "abstractKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LearnOpportunityELearningContentTypeDefinition",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProjectContentButtonCME_LearnOpportunityFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityBranchTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NodeTagList_LearnOpportunityRootStructureDefinitionFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InfectopharmTreeConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "restartTriesCount",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isRewardedTo",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardExpired",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showAnswerExpired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NodeContentElementsList_NodeFragment"
        }
      ],
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lengthInSecondsOverride",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cmePoints",
                      "storageKey": null
                    }
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectNodeTopBar_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeConfigurationsDisplay_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeDescription_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentHeftDisplay_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "b120efe513d03f45425f3509bb72c474";

export default node;

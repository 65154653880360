/**
 * @generated SignedSource<<02a243399233d8657a97c670bf0b07ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeCard_NodeFragment$data = {
  readonly id: string;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly configConsequences?: {
        readonly rewardExpired: boolean;
      };
      readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
      readonly isVisibleOnlyForAdmins?: boolean;
      readonly issuedCertificates?: ReadonlyArray<{
        readonly fileRef: {
          readonly url: string | null;
        } | null;
        readonly issuedAt: string;
      }>;
      readonly lengthInSecondsOverride?: number | null;
      readonly reducedDataInTree?: {
        readonly cmePoints: number | null;
      };
    };
    readonly icon?: {
      readonly url: string | null;
    } | null;
    readonly progressPercentage?: number | null;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"NodeCertificateDisplay_NodeRootStructureDefinitionFragment" | "NodeTagList_LearnOpportunityRootStructureDefinitionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NodeIcons_NodeFragment">;
  readonly " $fragmentType": "NodeCard_NodeFragment";
};
export type NodeCard_NodeFragment$key = {
  readonly " $data"?: NodeCard_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCard_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCard_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lengthInSecondsOverride",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isVisibleOnlyForAdmins",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IssuedCertificateV2",
                      "kind": "LinkedField",
                      "name": "issuedCertificates",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "issuedAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "File",
                          "kind": "LinkedField",
                          "name": "fileRef",
                          "plural": false,
                          "selections": (v0/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedDataInTree",
                      "kind": "LinkedField",
                      "name": "reducedDataInTree",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cmePoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstLearnOpportunityIdWithMultipleChoiceElement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InfectopharmTreeConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "rewardExpired",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "progressPercentage",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NodeTagList_LearnOpportunityRootStructureDefinitionFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NodeCertificateDisplay_NodeRootStructureDefinitionFragment"
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeIcons_NodeFragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "88450b161384515de8a111d53239067d";

export default node;

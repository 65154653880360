import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { readInlineData, usePaginationFragment } from "react-relay";
import { NodeCard } from "../../../infecto-lms-webapp-impl/components/NodeCard.component";
import { Nodes_QueryFragment$key } from "@generated/Nodes_QueryFragment.graphql";
import { Nodes_Refetch } from "@generated/Nodes_Refetch.graphql";
import { Nodes_NodeFragment$key } from "@generated/Nodes_NodeFragment.graphql";

const LEARN_OPPORTUNITIES_FRAGMENT = graphql`
	fragment Nodes_QueryFragment on Query
	@refetchable(queryName: "Nodes_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 5 }, after: { type: "String" }) {
		LearnV2 {
			SearchLearnOpportunities(
				first: $first
				after: $after
				searchOptions: [hideFinished, hideTreeNotRewarded]
			) @connection(key: "Nodes_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						...Nodes_NodeFragment
					}
				}
			}
		}
	}
`;

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment Nodes_NodeFragment on LearnOpportunityV2 @inline {
		id
		...NodeCard_NodeFragment
	}
`;

interface OwnProps {
	queryFragmentRef: Nodes_QueryFragment$key;
}

export function Nodes({ queryFragmentRef }: OwnProps) {
	const {
		data: {
			LearnV2: {
				SearchLearnOpportunities: { edges: learnOpportunities },
			},
		},
	} = usePaginationFragment<Nodes_Refetch, Nodes_QueryFragment$key>(
		LEARN_OPPORTUNITIES_FRAGMENT,
		queryFragmentRef,
	);

	return (
		<div className="grid">
			{learnOpportunities
				?.map((lo) => lo!.node!)
				.map((lo, index) => {
					const learnOpportunity = readInlineData<Nodes_NodeFragment$key>(
						LEARN_OPPORTUNITY_FRAGMENT,
						lo,
					);
					return (
						<div
							key={learnOpportunity.id + index}
							className={`${index === 0 ? "lg:col-12" : "lg:col-6"} col-12 `}
						>
							<NodeCard
								isFeatured={index === 0}
								learnOpportunityFragmentRef={learnOpportunity}
								isLandingPage={false}
							/>
						</div>
					);
				})}
		</div>
	);
}

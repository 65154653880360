import { MenuItem } from "primereact/menuitem";
import React from "react";
import { RouterMenuItem } from "../infecto-lms-webapp/components/navigation/RouterMenuItem";
import { LinkMenuItem } from "../infecto-lms-webapp/components/navigation/LinkMenuItem.component";
import { DASHBOARD_PATH } from "./router/routes/auth/dashboard.routes";
import { SEARCH_SCREEN_PATH } from "./router/routes/auth/search.routes";
import { TREE_STATES_PATH } from "./router/routes/auth/learn-state.routes";
import { MenuType } from "../infecto-lms-webapp/components/navigation/navigation.interface";
import { Award, Book } from "react-feather";
import { CERTIFICATE_SCREEN_PATH } from "./router/routes/auth/certificates.routes";
import { COMMUNICATION_SETTINGS_SCREEN_PATH } from "./router/routes/auth/communication-settings.routes";

export const MAIN_MENU_ITEMS: (menuType: MenuType, collapsed: boolean) => MenuItem[] = (
	menuType: MenuType,
	collapsed: boolean,
) => [
	{
		label: "Startseite",
		url: DASHBOARD_PATH,
		icon: "pi pi-home",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={"ProfileMenu"}
			/>
		),
	},
	{
		label: "Alle Module",
		url: SEARCH_SCREEN_PATH,
		icon: "pi pi-th-large",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={menuType}
			/>
		),
	},
	{
		label: "Veranstaltungen",
		url: "https://www.infectopharm.com/fortbildung/consilium/anmeldung-zu-fortbildungen/",
		icon: "pi pi-calendar",
		template: (item) => (
			<LinkMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				collapsed={collapsed}
				item={item}
				menuType={"ProfileMenu"}
			/>
		),
	},
	{
		label: "-Frage stellen",
		url: "https://www.infectopharm.com/consilium/frage-an-das-consilium/",
		icon: "pi pi-question",
		template: (item) => (
			<LinkMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				menuType={menuType}
				collapsed={collapsed}
				item={item}
				customedLabel={
					<span className="text-sm">
						<span style={{ fontStyle: "italic" }}>consilium</span>
						{item?.label}
					</span>
				}
			/>
		),
	},
	{
		label: "Meine Lernerfahrungen",
		url: TREE_STATES_PATH,
		icon: "award",
		template: (item) => (
			<RouterMenuItem
				onClick={() => {}}
				icon={
					<Award style={item.icon == "award" ? { marginLeft: -1 } : { marginLeft: 0 }} size={19} />
				}
				collapsed={collapsed}
				item={item}
				menuType={"ProfileMenu"}
			/>
		),
	},
	{
		label: "Meine Zertifikate",
		url: CERTIFICATE_SCREEN_PATH,
		icon: "book",
		template: (item) => (
			<RouterMenuItem
				icon={<Book size={19} />}
				menuType={menuType}
				onClick={() => {}}
				item={item}
				collapsed={collapsed}
			/>
		),
	},

	/*	{
		label: "Nachrichten",
		url: MESSAGING_SCREEN_PATH,
		icon: "pi pi-envelope",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={"ProfileMenu"}
			/>
		),
	},*/
	{
		label: "Einstellungen",
		url: COMMUNICATION_SETTINGS_SCREEN_PATH,
		icon: "pi pi-sliders-h",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={"ProfileMenu"}
			/>
		),
	},
];

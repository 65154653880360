import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useMatch } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { AnswersScreen_Query } from "@generated/AnswersScreen_Query.graphql";
import { ElementAnswers } from "../../infecto-lms-webapp/components/content-element/ElementAnswers.component";
import { NodeContainer } from "../../infecto-lms-webapp-impl/containers/Node.container";
import {
	getAnswersPath,
	LEARN_OPPORTUNITY_ID_URL_PARAM,
} from "../../infecto-lms-webapp-impl/router/routes/auth/learn.routes";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { SecondaryButton } from "../../infecto-lms-webapp/components/buttons/SecondaryButton";

const QUERY = graphql`
	query AnswersScreen_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			... on LearnOpportunityV2 {
				typeDefinition {
					definitionType
					... on LearnOpportunityELearningContentTypeDefinition {
						showAnswerElements {
							...ElementAnswers_ElementFragment
						}
					}
				}
				...NodeContainer_LearnOpportunityV2Fragment
			}
		}
	}
`;

export function AnswersScreen() {
	const {
		params: { learnOpportunityId },
	} = useMatch(getAnswersPath(LEARN_OPPORTUNITY_ID_URL_PARAM))!;

	const data = useLazyLoadQuery<AnswersScreen_Query>(QUERY, {
		learnOpportunityId: learnOpportunityId!,
	});

	const [currentElementIndex, setCurrentElementIndex] = useState<number>(0);

	const elements = data.node?.typeDefinition?.showAnswerElements;

	if (!elements) return null;

	const currentElement = elements[currentElementIndex];

	const percentage = elements.length ? currentElementIndex / elements.length : 0;

	return (
		<NodeContainer learnOpportunityFragmentRef={data.node}>
			<div className="flex flex-grow-1 flex-column">
				<div className="flex-grow-1">
					{currentElement && <ElementAnswers elementFragmentRef={currentElement} />}
				</div>
				<div className=" flex flex-column">
					<div>
						<ProgressBar
							color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
							style={{ height: "6px" }}
							showValue={false}
							value={percentage}
						/>
					</div>
					<div className="p-5 flex">
						<div className="ml-auto">
							<SecondaryButton
								label="Zurück"
								className="mr-4 pl-6 pr-6 "
								disabled={currentElementIndex === 0}
								onClick={() => {
									setCurrentElementIndex(currentElementIndex - 1);
								}}
							/>
							<Button
								label="Vorwärts"
								disabled={currentElementIndex === elements.length - 1}
								onClick={() => {
									setCurrentElementIndex(currentElementIndex + 1);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</NodeContainer>
	);
}

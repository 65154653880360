import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { SearchNodesScreen } from "../../../../app/screens/SearchNodes.screen";

export const SEARCH_SCREEN_PATH = "/alle-module";

export const SearchRoutes: PrivateRouteDefinition[] = [
	{
		path: SEARCH_SCREEN_PATH,
		component: <SearchNodesScreen />,
	},
];

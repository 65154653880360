import React from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { NodeCard } from "../../../infecto-lms-webapp-impl/components/NodeCard.component";
import { UserFinishedTreeStatesWithCertificate_Query } from "@generated/UserFinishedTreeStatesWithCertificate_Query.graphql";
import { UserFinishedTreeStatesWithCertificate_Refetch } from "@generated/UserFinishedTreeStatesWithCertificate_Refetch.graphql";
import { UserFinishedTreeStatesWithCertificate_QueryFragment$key } from "@generated/UserFinishedTreeStatesWithCertificate_QueryFragment.graphql";
import { PrimaryButton } from "../buttons/PrimaryButton";

const QUERY = graphql`
	query UserFinishedTreeStatesWithCertificate_Query {
		...UserFinishedTreeStatesWithCertificate_QueryFragment
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment UserFinishedTreeStatesWithCertificate_QueryFragment on Query
	@refetchable(queryName: "UserFinishedTreeStatesWithCertificate_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
		LearnV2 {
			SearchLearnOpportunities(
				first: $first
				after: $after
				isCertified: true
				searchOptions: [onlyShowFinished]
			) @connection(key: "UserFinishedTreeStatesWithCertificate_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						...NodeCard_NodeFragment
					}
				}
			}
		}
	}
`;

export function UserFinishedTreeStatesWithCertificate() {
	const query = useLazyLoadQuery<UserFinishedTreeStatesWithCertificate_Query>(QUERY, {});

	const { data, loadNext, hasNext } = usePaginationFragment<
		UserFinishedTreeStatesWithCertificate_Refetch,
		UserFinishedTreeStatesWithCertificate_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const results =
		data.LearnV2.SearchLearnOpportunities.edges?.filter((e) => !!e?.node).map((e) => e!.node!) ||
		[];

	return (
		<>
			<div className="grid w-12">
				{results?.map((learnOpportunity, index) => (
					<div className="lg:col-4 col-12" key={index}>
						<NodeCard isLandingPage={false} learnOpportunityFragmentRef={learnOpportunity} />
					</div>
				))}
				{results.length === 0 && (
					<div className="text-center col-12">
						Sie haben leider noch keine aktiven Lernerfahrungen.
					</div>
				)}
			</div>
			<div className="flex justify-content-center mt-4">
				{hasNext && <PrimaryButton label="Mehr laden..." onClick={() => loadNext(10)} />}
			</div>
		</>
	);
}

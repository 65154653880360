import React from "react";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { ImpressScreen_Query } from "@generated/ImpressScreen_Query.graphql";
import { defineDynamicHeightForContainers } from "../../infecto-lms-webapp/hooks/defineDynamicHeightForContainers";

const QUERY = graphql`
	query ImpressScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}
	}
`;
export function ImpressScreen() {
	usePageTitle("Impressum");

	const query = useLazyLoadQuery<ImpressScreen_Query>(QUERY, {}, { fetchPolicy: "network-only" });

	return (
		<DefaultContainer
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
			collapsed={false}
			screenName="Impress"
		>
			<MainContainerWithTopBar height={defineDynamicHeightForContainers()} className="bg-white p-5">
				<p>
					<h1>IMPRESSUM</h1>
				</p>
				<p>(Angaben gem&auml;&szlig; &sect; 5 TMG)</p>
				<p>InfectoPharm Arzneimittel und Consilium GmbHVon-Humboldt-Str. 164646 Heppenheim</p>
				<p>
					Tel.: 06252 957000Fax: 06252 958844E-Mail:&nbsp;
					<a href="mailto:kontakt@infectopharm.com" target="_blank" rel="noopener noreferrer">
						kontakt@infectopharm.com
					</a>
				</p>
				<p>
					Website:{" "}
					<a href="https://www.infectopharm.com" target="_blank" rel="noopener noreferrer">
						https://www.infectopharm.com
					</a>
				</p>
				<p>
					Gesch&auml;ftsf&uuml;hrer: Philipp Z&ouml;ller (Vors.), Michael Gilster, Dr. Markus
					Rudolph, Dr. Norbert Stempel
				</p>
				<p>Registergericht: Darmstadt&nbsp;&nbsp;HRB 24623USt.-IdNr.: DE 172949642</p>
				<p>Verantwortlich f&uuml;r den Inhalt: Dr. Markus Rudolph</p>
				<p>
					<strong>Rechtliche Hinweise</strong>
				</p>
				<p>
					F&uuml;r die Nutzung der Webseite https://www.wissen-wirkt.online gelten
					ausschlie&szlig;lich die&nbsp;
					<a href="src/app/screens/Impress.screen.tsx" target="_blank" rel="noopener noreferrer">
						Allgemeinen Gesch&auml;fts- und Nutzungsbedingungen
					</a>
					&nbsp;sowie die&nbsp;
					<a href="src/app/screens/Impress.screen.tsx" target="_blank" rel="noopener noreferrer">
						Datenschutzbestimmungen
					</a>
					.
				</p>
				<p>
					<strong>Quellenangaben f&uuml;r die verwendeten Bilder und Grafiken</strong>
				</p>
				<p>
					<a href="src/app/screens/Impress.screen.tsx" target="_blank" rel="noopener noreferrer">
						https://www.shutterstock.com/
					</a>
				</p>
				<p>
					<a href="src/app/screens/Impress.screen.tsx" target="_blank" rel="noopener noreferrer">
						https://www.gettyimages.de/
					</a>
				</p>
				<p>
					Alle verwendeten Bilder und Grafiken dienen nur zur Veranschaulichung. Bei allen Bildern
					und Grafiken, in denen Personen abgebildet sind, handelt es sich um mit Models gestellte
					Agenturfotos.
				</p>
				<p>
					<strong>Haftungsausschluss (Disclaimer)</strong>
				</p>
				<p>
					Die Inhalte dieser Plattform werden mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt
					zusammengestellt. Die InfectoPharm GmbH &uuml;bernimmt jedoch keine Gew&auml;hr f&uuml;r
					die Richtigkeit, Vollst&auml;ndigkeit, Fehler redaktioneller oder technischer Art,
					Auslassungen und Aktualit&auml;t der bereitgestellten Inhalte. Die Anwendung oder
					Umsetzung der Lerninhalte der Plattform erfolgen auf eigene Gefahr des Nutzers und
					unterliegen seiner Verantwortung als Angeh&ouml;riger der Fachkreise. Mit der Nutzung der
					Plattform der InfectoPharm GmbH ohne Registrierung kommt keinerlei Vertragsverh&auml;ltnis
					zwischen dem Nutzer und dem Anbieter zustande.
				</p>
				<p>
					Die verf&uuml;gbar gemachten Inhalte und Informationen sind akademischer Natur und dienen
					nur Informations- und Lernzwecken. Die InfectoPharm GmbH erstell keine Diagnosen und
					erteilt ausdr&uuml;cklich keine Ratschl&auml;ge oder Empfehlungen hinsichtlich der
					Therapie konkreter Erkrankungen. F&uuml;r Angaben &uuml;ber Verfahrenshinweise,
					Anwendungen, Applikationsformen und Dosierungen wird von der InfectoPharm GmbH keine
					Gew&auml;hr &uuml;bernommen. Jede Anwendung, Applikation oder Dosierung erfolgt auf eigene
					Gefahr des Nutzers. Die fachlichen Informationen, die durch die InfectoPharm GmbH
					verf&uuml;gbar gemacht werden, wurden von Fachleuten zusammen getragen, deren Anwendung
					und Umsetzung obliegt jedoch ausschlie&szlig;lich der Verantwortung des Nutzers der
					Plattform auf Basis seiner pers&ouml;nlichen und professionellen Einsch&auml;tzung als
					Mitglied der Fachkreise.
				</p>
				<p>
					Die InfectoPharm GmbH &uuml;bernimmt keine Haftung f&uuml;r eventuelle Konsequenzen, die
					durch die Beachtung bzw. Nicht-Beachtung der App-Inhalte entstehen.
				</p>
				<p>
					Die Nennung von Produkten bedeutet keine Empfehlung oder Aussage zu deren Qualit&auml;t
					und begr&uuml;ndet daher keine diesbez&uuml;gliche Haftung.
				</p>
				<p>
					<strong>Schutzrechte</strong>
				</p>
				<p>
					Die auf dieser Plattform ver&ouml;ffentlichten Inhalte unterliegen dem deutschen Urheber-
					und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht
					zugelassen Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
					jeweiligen Rechtsinhabers. Dies gilt insbesondere f&uuml;r Vervielf&auml;ltigung,
					Bearbeitung, &Uuml;bersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten
					in Datenbanken oder anderen elektronischen Medien und Systemen. Die unerlaubte
					Vervielf&auml;ltigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht
					gestattet und strafbar.
				</p>
				<p>
					Die Darstellung dieser Plattform in fremden Umgebungen bzw. auf anderen Internetseiten ist
					nur mit schriftlicher Erlaubnis zul&auml;ssig.
				</p>
				<p>
					<strong>Externe Links</strong>
				</p>
				<p>
					Trotz sorgf&auml;ltiger Pr&uuml;fung bei einer eventuellen Verlinkung oder Einbettung
					&uuml;bernimmt InfectoPharm GmbH f&uuml;r die Inhalte fremder Seiten keine Haftung.
					F&uuml;r den Inhalt fremder Seiten sind ausschlie&szlig;lich deren Betreiber
					verantwortlich, auch wenn diese mit wissenwirkt.com und/oder deren Unterseiten verlinkt
					oder darin eingebettet sind.
				</p>
				<p>
					<strong>R&uuml;ckmeldung</strong>
				</p>
				<p>&Uuml;ber Lob freuen wir uns, Kritik macht uns besser.</p>
				<p>Ihre Anregungen und Verbesserungsw&uuml;nsche nehmen wir gerne per E-Mail entgegen.</p>
				<p>
					R&uuml;ckmeldung:&nbsp;
					<a href="mailto:webmaster@infectopharm.com" target="_blank" rel="noopener noreferrer">
						webmaster@infectopharm.com
					</a>
				</p>
				<p>&copy; 2022 InfectoPharm Arzneimittel und Consilium GmbH</p>
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
}
